import React, { useState, useEffect, useCallback } from "react";
import { Element } from "react-scroll";
import { useSelector } from "react-redux";
import { FaChartArea, FaEye, FaGamepad, FaMagic } from "react-icons/fa";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import Plx from "react-plx";

import "./labHow.css";

let innerHeight = require("ios-inner-height");

const textDataAni = [
  {
    start: innerHeight() * 6.9,
    end: innerHeight() * 7.05,
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
];

const mobileData = [
  {
    start: innerHeight() * 7.2,
    end: innerHeight() * 7.3,
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
];

const mobileDataTwo = [
  {
    start: innerHeight() * 7.35,
    end: innerHeight() * 7.45,
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
];

const mobileDataThree = [
  {
    start: innerHeight() * 7.5,
    end: innerHeight() * 7.6,
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
];

const mobileDataFour = [
  {
    start: innerHeight() * 7.65,
    end: innerHeight() * 7.75,
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
];

const textDataTwo = [
  {
    start: 4200,
    end: 4500,
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
];

const textDataThree = [
  {
    start: 4650,
    end: 4750,
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
];

const textDataFive = [
  {
    start: ".HowInnerConsumer",
    duration: 10,
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
  {
    start: ".HowInnerCommercial",
    duration: 10,
    startOffset: innerHeight(),
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: "opacity",
      },
    ],
  },
  {
    start: ".HowInnerCommercial",
    duration: 10,
    startOffset: innerHeight(),
    properties: [
      {
        startValue: 0,
        endValue: 100,
        unit: "vw",
        property: "translateX",
      },
    ],
  },
];

const commercialDivOne = [
  {
    start: innerHeight() * 10.56,
    end: innerHeight() * 10.66,
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
  {
    start: innerHeight() * 11.01,
    end: innerHeight() * 11.11,
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: "opacity",
      },
    ],
  },
];

const commercialDivTwo = [
  {
    start: innerHeight() * 11.01,
    end: innerHeight() * 11.11,
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
  {
    start: innerHeight() * 11.46,
    end: innerHeight() * 11.56,
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: "opacity",
      },
    ],
  },
];
const commercialDivThree = [
  {
    start: innerHeight() * 11.46,
    end: innerHeight() * 11.56,
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
  {
    start: innerHeight() * 11.91,
    end: innerHeight() * 12.01,
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: "opacity",
      },
    ],
  },
];

const textData = {
  title: ["Hur?", "How?"],
  subtitle: [
    "VERT-appen är nu tillgänglig för iOS och Android. Den låter dig kontrollera och övervaka alla dina odlingar som är utrustade med ett VERT-system.",
    "The VERT App is now available for iOS and Android. It allows you to control and monitor all of your grows that are equipped with a VERT. system.",
  ],
  section11: ["Kontrollera", "Control"],
  section12: [
    "Ni kan styra enheter såsom LED-lampor, vattenpumpar och fläktar. ",
    "Control devices such as LED lights, water pumps, and fans.",
  ],

  section21: ["Övervaka", "Monitor"],
  section22: [
    "Övervaka värden för temperatur, luftfuktighet, ljusnivå (LUX), pH och EC i realtid samt övervaka manuellt genom live-streaming.",
    "See live values of temperature, humidity, light-level (LUX), pH and EC. Additionally, you can watch a live streams of your grow.",
  ],

  section31: ["Automatisera", "Automate"],
  section32: [
    "Automatisera ert system genom att ställa in scheman för lampor och pumpar samt regler för enheter baserat på sensorvärden.",
    "Automate your system by setting schedules for lights and pumps and rules for devices based on sensor values.",
  ],

  section41: ["Analysera", "Analyze"],
  section42: [
    "Analysera er tillväxtdata genom att visa historiska värden och sortera dem i grafer och diagram.",
    "Analyze your grow data by viewing historic values and sorting them in graphs and diagrams.",
  ],

  section51: [
    "Alla databas- och logikoperationer hanteras av VERT.cloud. Eftersom de flesta logiska operationer hanteras i molnet kräver enheter inte upprepade uppdateringar, eftersom endast molnoperationerna behöver uppdateras. Detta skapar ett bättre flöde av uppdateringar som användarna knappt märker.",
    "All database and logic operations are handled by VERT.cloud. Since most logical operations are managed in the cloud, devices do not require repetitive updates, as only the cloud operations need to be updated. This creates a better flow of updates that users hardly notice.",
  ],
  section52: [
    "Alla sensordata, ljustimmar etc. sparas anonymt i VERT.cloud och bearbetas sedan för att optimera korrekta värden för varje enskild anläggning.",
    "All sensor data, light hours, etc., are anonymously saved in VERT.cloud and then processed to optimize the correct values for each different plant.",
  ],
  consumerTitle: ["Konsument", "Consumer"],
  consumer1: [
    "Gör nytta av ett oanvänt rum i källaren med vårt modulsystem eller låt örter och växter grönska i ditt vardagsrum med vårt allt-i-ett-system.",
    "Make use of a unused room in your basement with our modular system or let herbs and leafy greens grow in your living room with our all-in-one system.",
  ],
  commercialTitle: ["Kommersiellt", "Commercial"],
  commercial11: [
    "Hyllsystem för restauranger, affärer och kontor",
    "Shelf System for restaurants, shops & offices",
  ],
  commercial12: [
    "Specialanpassade hyllsystem med många användningsområden.",
    "Custom built shelf systems for any use.",
  ],
  commercial21: ["Industriellt system", "Industrial system"],
  commercial22: [
    "Specialanpassad för din byggnad inklusive VERT. teknologi för att kunna styras med VERT. appen.",
    "Custom built for your building including VERT. technology to control with the VERT. App!",
  ],
  commercial31: ["Container System", "Container System"],
  commercial32: [
    "Specialanpassad container enligt dina önskemål inklusive VERT. teknologi.",
    "Custom built container according to your wishes including VERT. technology.",
  ],
};

function LabHow(props) {
  const userLanguage = useSelector((state) => state.app.language);

  const [consumerState, setConsumerState] = useState("fixed");

  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  useEffect(() => {
    if (props.scrollValue) {
      setFrame();
    }
  }, [props.scrollValue]);

  const setFrame = () => {
    if (innerHeight() * 9.91 < window.pageYOffset) {
      if (consumerState !== "absolute") {
        return setConsumerState("absolute");
      }
    } else {
      if (consumerState !== "fixed") {
        return setConsumerState("fixed");
      }
    }
  };

  return (
    <Element name="lab/how" className="LabHow">
      <div className="LabHow" style={{ height: innerHeight() * 5.25 }}>
        <div className="HowHeader" style={{ height: innerHeight() * 0.25 }}>
          <p className="HowTitle">{textData.title[userLanguage]}</p>
        </div>
        <div className="HowInnerStart" style={{ height: innerHeight() * 1.2 }}>
          <Plx
            className="HowMobileDiv"
            parallaxData={textDataAni}
            style={{ height: innerHeight() * 1.2 }}
          >
            <img
              src={
                "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fmobile.png?alt=media"
              }
              alt="MobileLogo"
              style={{ height: innerHeight() * 0.4 }}
            />
            <div
              className="HowMobileDivInner"
              style={{ height: innerHeight() * 0.7 }}
            >
              <p className="LogoText">{textData.subtitle[userLanguage]}</p>
              <div className="HowMobileDivInnerList">
                <Plx className="HowMobileDivInnerPlx" parallaxData={mobileData}>
                  <div className="HowMobileDivInnerPlxInner">
                    <FaGamepad
                      style={{
                        fontSize: "300%",
                        color: "#44a99a",
                        opacity: 0.85,
                      }}
                    />
                    <p className="HowMobileDivInnerPlxTitle">
                      {textData.section11[userLanguage]}
                    </p>
                  </div>
                  <p className="HowMobileDivInnerPlxSubtitle2">
                    {textData.section12[userLanguage]}
                  </p>
                </Plx>
                <Plx
                  className="HowMobileDivInnerPlx"
                  parallaxData={mobileDataTwo}
                >
                  <div className="HowMobileDivInnerPlxInner">
                    <FaEye
                      style={{
                        fontSize: "300%",
                        color: "#44a99a",
                        opacity: 0.85,
                      }}
                    />
                    <p className="HowMobileDivInnerPlxTitle">
                      {textData.section21[userLanguage]}
                    </p>
                  </div>
                  <p className="HowMobileDivInnerPlxSubtitle2">
                    {textData.section22[userLanguage]}
                  </p>
                </Plx>
                <Plx
                  className="HowMobileDivInnerPlx"
                  parallaxData={mobileDataThree}
                >
                  <div className="HowMobileDivInnerPlxInner">
                    <FaMagic
                      style={{
                        fontSize: "300%",
                        color: "#44a99a",
                        opacity: 0.85,
                      }}
                    />
                    <p className="HowMobileDivInnerPlxTitle">
                      {textData.section31[userLanguage]}
                    </p>
                  </div>
                  <p className="HowMobileDivInnerPlxSubtitle2">
                    {textData.section32[userLanguage]}
                  </p>
                </Plx>
                <Plx
                  className="HowMobileDivInnerPlx"
                  parallaxData={mobileDataFour}
                >
                  <div className="HowMobileDivInnerPlxInner">
                    <FaChartArea
                      style={{
                        fontSize: "300%",
                        color: "#44a99a",
                        opacity: 0.85,
                      }}
                    />
                    <p className="HowMobileDivInnerPlxTitle">
                      {textData.section41[userLanguage]}
                    </p>
                  </div>
                  <p className="HowMobileDivInnerPlxSubtitle2">
                    {textData.section42[userLanguage]}
                  </p>
                </Plx>
              </div>
            </div>
          </Plx>
        </div>
        <div className="HowInnerCloud" style={{ height: innerHeight() * 1.2 }}>
          <Particles
            id="tsparticles"
            init={particlesInit}
            loaded={particlesLoaded}
            options={{
              background: {
                color: {
                  value: "#323741",
                },
              },
              style: {
                position: "absolute",
                opacity: 0.25,
              },
              fpsLimit: 120,
              interactivity: {
                events: {
                  onHover: {
                    enable: true,
                    mode: "repulse",
                  },
                  resize: true,
                },
                modes: {
                  push: {
                    quantity: 4,
                  },
                  repulse: {
                    distance: 200,
                    duration: 0.4,
                  },
                },
              },
              particles: {
                color: {
                  value: "#ffffff",
                },
                links: {
                  color: "#ffffff",
                  distance: 150,
                  enable: true,
                  opacity: 0.5,
                  width: 1,
                },
                collisions: {
                  enable: true,
                },
                move: {
                  directions: "none",
                  enable: true,
                  outModes: {
                    default: "bounce",
                  },
                  random: false,
                  speed: 1,
                  straight: false,
                },
                number: {
                  density: {
                    enable: true,
                    area: 800,
                  },
                  value: 70,
                },
                opacity: {
                  value: 0.5,
                },
                shape: {
                  type: "circle",
                },
                size: {
                  value: { min: 1, max: 5 },
                },
              },
              detectRetina: true,
            }}
          />
          <div
            className="HowInnerCloudMargin"
            style={{ height: innerHeight() * 1.2 }}
          >
            <Plx className="HowFirebase" parallaxData={textDataTwo}>
              <img
                src={
                  "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fcloud.png?alt=media"
                }
                alt="MobileLogo"
                className="HowCloudImage"
                style={{ height: innerHeight() * 0.55 }}
              />
              <Plx
                className="HowGoogle"
                parallaxData={textDataThree}
                style={{ height: innerHeight() * 0.45 }}
              >
                <p className="OrTextNoMargin">VERT.cloud</p>
                <p className="FirebaseText">
                  {textData.section51[userLanguage]}
                </p>
                <p className="FirebaseText">
                  {textData.section52[userLanguage]}
                </p>
              </Plx>
            </Plx>
          </div>
        </div>
        <div className="HowInnerConsumer" style={{ height: innerHeight() }}>
          <Plx
            className="HowInnerConsumerMargin"
            style={{ height: innerHeight(), position: consumerState }}
            parallaxData={textDataFive}
          >
            <div
              className="HowConsumerLogos"
              style={{ height: innerHeight() * 0.35 }}
            >
              <div className="HowModularOne">
                <img
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fcon.png?alt=media"
                  }
                  alt="ModularLogo"
                  className="ModularLogo"
                />
              </div>
              <div className="HowModularOne">
                <img
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fnut.png?alt=media"
                  }
                  alt="ModularLogo"
                  className="ModularLogo"
                />
              </div>
              <div className="HowModularOne">
                <img
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fcli.png?alt=media"
                  }
                  alt="ModularLogo"
                  className="ModularLogo"
                />
              </div>
              <div className="HowModularOne">
                <img
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fcam.png?alt=media"
                  }
                  alt="ModularLogo"
                  className="ModularLogo"
                />
              </div>
            </div>
            <div
              className="HowConsumerTextDiv"
              style={{ height: innerHeight() * 0.2 }}
            >
              <p className="OrTextNoMargin">
                {textData.consumerTitle[userLanguage]}
              </p>
              <p className="HowConsumerText">
                {textData.consumer1[userLanguage]}
              </p>
            </div>
            <img
              src={
                "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fcombine.png?alt=media"
              }
              alt="ComLogo"
              className="ComLogo"
              style={{ height: innerHeight() * 0.35 }}
            />
          </Plx>
        </div>
        <div
          className="HowInnerCommercial"
          style={{ height: innerHeight() * 1.6 }}
        >
          <div
            className="HowInnerCommercialMargin"
            style={{ height: innerHeight() * 1.6 }}
          >
            <div
              className="HowConsumerTextDiv"
              style={{ height: innerHeight() * 0.25 }}
            >
              <p className="OrTextNoMargin">
                {textData.commercialTitle[userLanguage]}
              </p>
            </div>
            <div
              className="HowCommercialLogos"
              style={{ height: innerHeight() * 1.35 }}
            >
              <div
                className="HowCommercialInner"
                style={{ height: innerHeight() * 0.45 }}
              >
                <Plx
                  className="HowCommercialDiv"
                  style={{ height: innerHeight() * 0.45 }}
                  parallaxData={commercialDivOne}
                >
                  <p className="HowCommercialText">
                    {textData.commercial11[userLanguage]}
                  </p>
                  <p className="HowMobileDivInnerPlxSubtitle">
                    {textData.commercial12[userLanguage]}
                  </p>
                </Plx>
                <img
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fcommercial.jpg?alt=media"
                  }
                  alt="CommercialLogo"
                  className="CommercialLogo"
                  style={{ height: innerHeight() * 0.45 }}
                />
              </div>
              <div
                className="HowCommercialInner"
                style={{ height: innerHeight() * 0.45 }}
              >
                <Plx
                  className="HowCommercialDiv"
                  style={{ height: innerHeight() * 0.45 }}
                  parallaxData={commercialDivTwo}
                >
                  <p className="HowCommercialText">
                    {textData.commercial21[userLanguage]}
                  </p>
                  <p className="HowMobileDivInnerPlxSubtitle">
                    {textData.commercial22[userLanguage]}
                  </p>
                </Plx>
                <img
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Findustrial.jpg?alt=media"
                  }
                  alt="CommercialLogo"
                  className="CommercialLogo"
                  style={{ height: innerHeight() * 0.45 }}
                />
              </div>
              <div
                className="HowCommercialInner"
                style={{ height: innerHeight() * 0.45 }}
              >
                <Plx
                  className="HowCommercialDiv"
                  style={{ height: innerHeight() * 0.45 }}
                  parallaxData={commercialDivThree}
                >
                  <p className="HowCommercialText">
                    {textData.commercial31[userLanguage]}
                  </p>
                  <p className="HowMobileDivInnerPlxSubtitle">
                    {textData.commercial32[userLanguage]}
                  </p>
                </Plx>
                <img
                  src={
                    "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fshipping.jpg?alt=media"
                  }
                  alt="CommercialLogo"
                  className="CommercialLogo"
                  style={{ height: innerHeight() * 0.45 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
}

export default LabHow;
