import React from "react";
import { Element } from "react-scroll";
import { useRef } from "react";
import { useSelector } from "react-redux";

import { partnerInfo } from "../../../components/misc/enums";

import "./farmPartners.css";

let innerHeight = require("ios-inner-height");

const textData = {
  subtitle: [
    "Vi är stolta över att samarbeta med några av de bästa återförsäljarna i närområdet för att leverera våra färska och näringsrika grödor till ditt bord. Vårt samarbete med dessa återförsäljare gör att vi kan nå ut till fler kunder och dela fördelarna med våra hållbart odlade microgreens med er.",
    "We're proud to work with some of the best retailers in the area to bring our fresh and nutritious microgreens to your table.Our partnerships with these retailers allow us to reach more customers and share the benefits of our sustainably grown microgreens with the local community.",
  ],
  subtitle2: [
    "På den här sidan kommer vi att presentera våra betrodda återförsäljare och förse dig med all information du behöver för att hitta våra microgreens i en butik eller hos en återförsäljare nära dig. Oavsett om du är en husmanskock, restaurangägare eller matentusiast, är vi glada över att ha dig med oss i vårt uppdrag att främja hälsosam och god mat.",
    "In this page, we'll introduce you to our trusted retailers and provide you with all the information you need to find our microgreens at a store or reseller near you.Whether you're a home cook, a restaurant owner, or a food enthusiast, we're excited to have you join us on our mission to promote healthy and delicious eating.",
  ],
  store1: ["BUTIK", "STORE"],
  store2: ["GROSSIST", "RESELLER"],
  store3: ["ÖVRIGT", "OTHER"],
  header: ["ÅTERFÖRSÄLJARE", "RETAILERS"],
};

function FarmPartners() {
  const ref = useRef();
  const userLanguage = useSelector((state) => state.app.language);

  return (
    <Element name="farm/partners" className="FarmPartners">
      <div className="FarmPartnersInner" ref={ref}>
        <div className="FarmPartnersHeader">
          <p className="FarmPartnersHeaderText">
            {textData.header[userLanguage]}
          </p>
        </div>
      </div>
      <div
        className="FarmPartnersListOuter"
        style={{ marginTop: -innerHeight() }}
      >
        <p className="FarmPartnersTopText">{textData.subtitle[userLanguage]}</p>
        <p className="FarmPartnersTopText">
          {textData.subtitle2[userLanguage]}
        </p>
        <div className="FarmPartnersListInner">
          <p className="FarmPartnersListInnerDivText">
            {textData.store1[userLanguage]}
          </p>
          {partnerInfo.store.map((storeItem, storeIndex) => (
            <div
              key={"store" + storeIndex}
              className="FarmPartnersListInnerDiv"
            >
              <a
                href={storeItem.url}
                target="_blank"
                rel="noreferrer"
                className="FarmPartnersListInnerDivTop"
              >
                <img
                  src={storeItem.image}
                  srcSet={storeItem.image}
                  alt={storeItem.name}
                  loading="lazy"
                  className="FarmProductsListImg"
                />
              </a>
              <div className="FarmPartnersListInnerDivBottom">
                <p className="FarmPartnersListInnerDivBottomTitle">
                  {storeItem.name}
                </p>
                <p className="FarmPartnersListInnerDivBottomText">
                  {storeItem.address}
                </p>
              </div>
            </div>
          ))}
          <p className="FarmPartnersListInnerDivText">
            {textData.store2[userLanguage]}
          </p>
          {partnerInfo.retail.map((retailItem, retailIndex) => (
            <div
              key={"retail" + retailIndex}
              className="FarmPartnersListInnerDiv"
            >
              <a
                href={retailItem.url}
                target="_blank"
                rel="noreferrer"
                className="FarmPartnersListInnerDivTop"
              >
                <img
                  src={retailItem.image}
                  srcSet={retailItem.image}
                  alt={retailItem.name}
                  loading="lazy"
                  className="FarmProductsListImg"
                />
              </a>
              <div className="FarmPartnersListInnerDivBottom">
                <p className="FarmPartnersListInnerDivBottomTitle">
                  {retailItem.name}
                </p>
                <p className="FarmPartnersListInnerDivBottomText">
                  {retailItem.address}
                </p>
              </div>
            </div>
          ))}
          <p className="FarmPartnersListInnerDivText">
            {textData.store3[userLanguage]}
          </p>
          {partnerInfo.other.map((otherItem, otherIndex) => (
            <div
              key={"other" + otherIndex}
              className="FarmPartnersListInnerDiv"
            >
              <a
                href={otherItem.url}
                target="_blank"
                rel="noreferrer"
                className="FarmPartnersListInnerDivTop"
              >
                <img
                  src={otherItem.image}
                  srcSet={otherItem.image}
                  alt={otherItem.name}
                  loading="lazy"
                  className="FarmProductsListImg"
                />
              </a>
              <div className="FarmPartnersListInnerDivBottom">
                <p className="FarmPartnersListInnerDivBottomTitle">
                  {otherItem.name}
                </p>
                <p className="FarmPartnersListInnerDivBottomText">
                  {otherItem.address}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Element>
  );
}

export default FarmPartners;
