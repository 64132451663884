import React from "react";
import { styled } from "@mui/material/styles";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { useSpring, animated as a } from "@react-spring/web";
import "./start.css";

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: 200,
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0.15,
    },
    "& .MuiImageMarked-root": {
      opacity: 0,
    },
    "& .MuiTypography-root": {
      border: "4px solid rgba(0, 155, 140, 0.85)",
    },
  },
}));

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.white,
  opacity: 0.3,
  transition: theme.transitions.create("opacity"),
}));

const ImageMarked = styled("span")(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: "rgba(0, 155, 140, 1)",
  position: "absolute",
  bottom: -2,
  left: "calc(50% - 9px)",
  transition: theme.transitions.create("opacity"),
}));

export const MainButtonFarm = (props) => {
  const navigate = useNavigate();

  const toneOut = useSpring({
    config: { duration: 200 },
    opacity: props.grayscale ? 0 : 1,
  });

  const buttonClick = () => {
    props.setGrayscale(true);
    setTimeout(() => {
      navigate(`/farm`);
    }, 1000);
  };

  return (
    <a.div style={toneOut}>
      <ImageButton
        focusRipple
        disabled={props.showButton === false}
        onClick={buttonClick}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          left: 0,
          top: 0,
        }}
      >
        <ImageBackdrop className="MuiImageBackdrop-root" />
        {props.showButton ? (
          <Image>
            <Typography
              component="span"
              variant="h5"
              sx={{
                color: "black",
                position: "relative",
                p: 4,
                pt: 2,
                pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
              }}
            >
              {"farm"}
              <ImageMarked className="MuiImageMarked-root" />
            </Typography>
          </Image>
        ) : null}
      </ImageButton>
    </a.div>
  );
};

export const MainButtonLabs = (props) => {
  const navigate = useNavigate();

  const toneOut = useSpring({
    config: { duration: 200 },
    opacity: props.grayscale ? 0 : 1,
  });

  const buttonClick = () => {
    props.setGrayscale(true);
    setTimeout(() => {
      navigate(`/lab`);
    }, 1000);
  };
  return (
    <a.div style={toneOut}>
      <ImageButton
        focusRipple
        onClick={buttonClick}
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          left: 0,
          top: 0,
        }}
      >
        <ImageBackdrop className="MuiImageBackdrop-root" />
        <Image>
          <Typography
            component="span"
            variant="h5"
            sx={{
              color: "black",
              position: "relative",
              p: 4,
              pt: 2,
              pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
            }}
          >
            {"lab"}
            <ImageMarked className="MuiImageMarked-root" />
          </Typography>
        </Image>
      </ImageButton>
    </a.div>
  );
};
//<ErtLogo/>
