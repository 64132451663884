import React, { useRef } from "react";
import { useSpring, animated as a, config } from "@react-spring/web";
import { Element } from "react-scroll";
import { useSelector } from "react-redux";
import MainLogo from "../../../components/design/MainLogo";
import LanguagePicker from "../../../components/misc/LanguagePicker";
import "./farmStart.css";

let innerHeight = require("ios-inner-height");

const textData = {
  subtitle: [
    "Din Lokala Producent av Färska & Näringsrika ",
    "Your Trusted Local Source for Fresh & Nutrient-Packed ",
  ],
  subtitle2: ["Microgreens!", "Microgreens!"],
};
function FarmStart(props) {
  const ref = useRef();
  const userLanguage = useSelector((state) => state.app.language);

  const heightTransition = useSpring({
    to: { height: 0 },
    from: { height: innerHeight() },
    delay: 800,
    config: config.slow,
  });

  const titleTransition = useSpring({
    to: { y: 0 },
    from: { y: 100 },
    delay: 900,
    config: config.slow,
  });

  const textPosition = useSpring({
    position: ref.current
      ? props.scrollValue < innerHeight() - ref.current.offsetTop
        ? "fixed"
        : "absolute"
      : "fixed",
    y: ref.current
      ? props.scrollValue < innerHeight() - ref.current.offsetTop
        ? 0
        : innerHeight() - ref.current.offsetTop
      : 0,
    config: { duration: 0 },
  });

  return (
    <Element
      style={{ height: innerHeight() }}
      name="start"
      className="FarmStart"
    >
      <div className="FarmStartOuter">
        <MainLogo logoColor={false} textColor={true} noStartAni />
        <a.div
          ref={ref}
          className="StartNewInnerTextOuter"
          style={textPosition}
        >
          <div className="StartNewInnerTextDiv">
            <a.p className="StartNewInnerTitle" style={titleTransition}>
              VERT.farm
            </a.p>
          </div>
          <div className="StartNewInnerTextDiv">
            <a.p className="StartNewInnerText" style={titleTransition}>
              {textData.subtitle[userLanguage]}
              <span style={{ color: "#45aa9b", opacity: 1 }}>
                {textData.subtitle2[userLanguage]}
              </span>
            </a.p>
          </div>
        </a.div>
      </div>
      <a.div className="FarmStartOverlay" style={heightTransition}>
        <div className="FarmStartOverlayLogo" style={{ height: innerHeight() }}>
          <MainLogo logoColor={true} textColor={true} noStartAni />
        </div>
      </a.div>
      <LanguagePicker />
    </Element>
  );
}

export default FarmStart;