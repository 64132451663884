import React, { useState, useEffect } from "react";
import { Element } from "react-scroll";
import { useSelector } from "react-redux";
import Plx from "react-plx";

import "./labIntro.css";

let innerHeight = require("ios-inner-height");

const PeopleData = [
  {
    start: 0,
    end: 1,
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
  {
    start: innerHeight() * 1.6,
    end: innerHeight() * 1.7,
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: "opacity",
      },
    ],
  },
];
const FarmData = [
  {
    start: innerHeight() * 1.7,
    end: innerHeight() * 1.8,
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
  {
    start: innerHeight() * 2.8,
    end: innerHeight() * 2.9,
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: "opacity",
      },
    ],
  },
];

const CityData = [
  {
    start: innerHeight() * 2.9,
    end: innerHeight() * 3.0,
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
  {
    start: innerHeight() * 6,
    end: innerHeight() * 6.05,
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: "opacity",
      },
    ],
  },
];

const textData = {
  first1: [
    "Med en beräknad global befolkning som kommer att överstiga 10 miljarder år 2050",
    "With the global population projected to surpass 10 billion by 2050,",
  ],
  firs2: [
    "blir utmaningen att tillhandahålla hållbar, effektiv och kostnadseffektiv mat till alla allt större. Vertikalt jordbruk, en växande trend, kan vara framtiden för livsmedelsproduktion genom att övervinna begränsningarna av säsongsbetonade vädermönster, transportutmaningar och avkastningshöjningar.",
    "the challenge of providing sustainable, efficient, and cost-effective food for everyone is becoming increasingly significant. Vertical farming, a growing trend, could be the future of food production by overcoming the limitations of seasonal weather patterns, transportation challenges, and increasing yields.",
  ],
  second1: [
    "Även om modern teknik har lett till ökade produktionshastigheter,",
    "Although modern techniques have led to enhanced production rates, ",
  ],
  second2: [
    "används nu över 11% av världens mark för växtodling, vilket orsakar miljöutmaningar som avskogning, markförstöring och sätter en enorm press på vår planets resurser.",
    "over 11% of the world's land is now used for crop production, causing environmental challenges such as habitat clearance and soil degradation and placing immense pressure on our planet's resources.",
  ],
  third1: ["När våra städer expanderar", "Furthermore, as our cities expand, "],
  third2: [
    "ökar dessutom avståndet mellan lämplig jordbruksmark och de stora befolkningarna som konsumerar deras produkter, vilket förvärrar transporternas påverkan.",
    "the distance between suitable farming land and the large populations who consume their produce is increasing, which exacerbates the impact of transportation. ",
  ],

  four1: [
    "En lösning på detta problem är konceptet vertikalt jordbruk,",
    "One solution to this issue is the concept of vertical farming, ",
  ],
  four2: [
    "där vidsträckta odlingsgårdar kondenseras till mindre fabriksliknande platser som kan optimera förhållandena och avsevärt öka avkastningen.",
    "where sprawling crop farms are condensed into smaller factory-like sites that can optimize conditions and significantly increase yields.",
  ],
};

function LabIntro(props) {
  const userLanguage = useSelector((state) => state.app.language);

  const [peopleImages, setPeopleImages] = useState(null);
  const [cityImages, setCityImages] = useState(null);
  const [farmImages, setFarmImages] = useState(null);

  const [activePeopleImage, setActivePeopleImage] = useState(0);
  const [activeCityImage, setActiveCityImage] = useState(0);
  const [activeFarmImage, setActiveFarmImage] = useState(0);

  useEffect(() => {
    if (cityImages === null) {
      let tempArr = [];
      for (let i = 0; i < 96; i++) {
        let image = new Image();
        image.value = i;
        if (i < 10) {
          image.src = `https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flab%2FcityFrames%2Fframe-00000${i}.jpg?alt=media`;
        } else {
          image.src = `https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flab%2FcityFrames%2Fframe-0000${i}.jpg?alt=media`;
        }
        image.onload = () => {
          tempArr.push(image);
          if (tempArr.length === 96) {
            tempArr.sort((a, b) => a.value - b.value);
            setCityImages(tempArr);
          }
        };
      }
    }
  }, []);
  useEffect(() => {
    if (farmImages === null) {
      let tempArr = [];
      for (let i = 0; i < 96; i++) {
        let image = new Image();
        image.value = i;
        if (i < 10) {
          image.src = `https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flab%2FfarmFrames%2Fframe-00000${i}.jpg?alt=media`;
        } else {
          image.src = `https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flab%2FfarmFrames%2Fframe-0000${i}.jpg?alt=media`;
        }
        image.onload = () => {
          tempArr.push(image);
          if (tempArr.length === 96) {
            tempArr.sort((a, b) => a.value - b.value);
            setFarmImages(tempArr);
          }
        };
      }
    }
  }, []);

  useEffect(() => {
    if (peopleImages === null) {
      let tempArr = [];
      for (let i = 0; i < 96; i++) {
        let image = new Image();
        image.value = i;
        if (i < 10) {
          image.src = `https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flab%2FpeopleFrames%2Fframe-00000${i}.jpg?alt=media`;
        } else {
          image.src = `https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Flab%2FpeopleFrames%2Fframe-0000${i}.jpg?alt=media`;
        }
        image.onload = () => {
          tempArr.push(image);
          if (tempArr.length === 96) {
            tempArr.sort((a, b) => a.value - b.value);
            setPeopleImages(tempArr);
          }
        };
      }
    }
  }, []);

  useEffect(() => {
    if (props.scrollValue) {
      setFrame();
    }
  }, [props.scrollValue]);

  const setFrame = () => {
    if (
      innerHeight() * 0.2 < window.pageYOffset &&
      innerHeight() * 1.7 > window.pageYOffset
    ) {
      let height =
        (window.pageYOffset - innerHeight() * 0.2) / (innerHeight() * 1.5);
      let frame = Math.round(height * 96);

      if (frame <= 95) {
        return setActivePeopleImage(frame);
      }
    }

    if (
      innerHeight() * 1.7 < window.pageYOffset &&
      innerHeight() * 2.9 > window.pageYOffset
    ) {
      let height =
        (window.pageYOffset - innerHeight() * 1.7) / (innerHeight() * 1.2);
      let frame = Math.round(height * 96);

      if (frame <= 95) {
        return setActiveFarmImage(frame);
      }
    }

    if (
      innerHeight() * 2.9 < window.pageYOffset &&
      innerHeight() * 4.1 > window.pageYOffset
    ) {
      let height =
        (window.pageYOffset - innerHeight() * 2.9) / (innerHeight() * 1.2);
      let frame = Math.round(height * 96);

      if (frame <= 95) {
        return setActiveCityImage(frame);
      }
    }
  };

  return (
    <Element name="lab/intro">
      <div className="LabIntro" style={{ height: innerHeight() * 4.8 }}>
        <div className="IntroInner">
          <Plx
            className="IntroPlx"
            parallaxData={PeopleData}
            animateWhenNotInViewport={true}
            style={{ height: innerHeight() * 1.2 }}
          >
            {peopleImages !== null ? (
              <img
                src={peopleImages[activePeopleImage].src}
                alt="FirebaseLogo"
                style={{ height: innerHeight() }}
                className="EarthLogo"
              />
            ) : null}

            <div
              className="IntroTextDiv"
              style={{
                height: innerHeight() * 0.3,
                marginTop: innerHeight() * 0.1,
              }}
            >
              <p className="IntroTitle">
                {textData.first1[userLanguage]}
                <br></br>
                <span className="IntroText">
                  {textData.firs2[userLanguage]}
                </span>
              </p>
            </div>
          </Plx>

          <Plx
            className="IntroPlx"
            parallaxData={FarmData}
            animateWhenNotInViewport={true}
            style={{ height: innerHeight() * 1.2 }}
          >
            {farmImages !== null ? (
              <img
                src={farmImages[activeFarmImage].src}
                alt="FirebaseLogo"
                className="EarthLogo"
                style={{ height: innerHeight() }}
              />
            ) : null}
            <div
              className="IntroTextDiv"
              style={{
                height: innerHeight() * 0.3,
                marginTop: innerHeight() * 0.1,
              }}
            >
              <p className="IntroTitle">
                {textData.second1[userLanguage]}
                <br></br>
                <span className="IntroText">
                  {textData.second2[userLanguage]}
                </span>
              </p>
            </div>
          </Plx>
          <Plx
            className="IntroPlx"
            parallaxData={CityData}
            animateWhenNotInViewport={true}
            style={{ height: innerHeight() * 1.2 }}
          >
            {cityImages !== null ? (
              <img
                src={cityImages[activeCityImage].src}
                alt="FirebaseLogo"
                className="EarthLogo"
                style={{ height: innerHeight() }}
              />
            ) : null}
            <div
              className="IntroTextDiv"
              style={{
                height: innerHeight() * 0.3,
                marginTop: innerHeight() * 0.1,
              }}
            >
              <p className="IntroTitle">
                {textData.third1[userLanguage]}
                <br></br>
                <span className="IntroText">
                  {textData.third2[userLanguage]}
                </span>
              </p>
            </div>
          </Plx>

          <div className="IntroGrowDiv" style={{ height: innerHeight() * 1.2 }}>
            <div
              className="IntroGrowTextDiv"
              style={{
                height: innerHeight() * 0.35,
                marginTop: innerHeight() * 0.25,
              }}
            >
              <p className="IntroTitle">
                {textData.four1[userLanguage]}
                <br></br>
                <span className="IntroText">
                  {textData.four2[userLanguage]}
                </span>
              </p>
            </div>
            <img
              src={
                "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fshelf.jpeg?alt=media"
              }
              alt="FirebaseLogo"
              className="IndustrialLogo"
              style={{ height: innerHeight() * 0.6 }}
            />
          </div>
        </div>
      </div>
    </Element>
  );
}

export default LabIntro;
