import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { logEvent } from "firebase/analytics";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import OfflineBoltOutlinedIcon from "@mui/icons-material/OfflineBoltOutlined";
import ArrowBackIosOutlinedIcon from "@mui/icons-material/ArrowBackIosOutlined";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import ColorLensOutlinedIcon from "@mui/icons-material/ColorLensOutlined";
import { Carousel } from "react-responsive-carousel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import moment from "moment";
import { useSelector } from "react-redux";

import { animateScroll as scroll, Element, scroller } from "react-scroll";

import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import { plantInfoNew } from "../../../components/misc/enums";
import Loading from "../../../components/misc/Loading";
import { analyticsObj } from "../../../components/firebase/firestore";
import FarmProductTrace from "./FarmProductTrace";
import ContactOuter from "../../contact/ContactOuter";
import { getDates } from "../../../components/firebase/firestore";

import "./farmProducts.css";
import "./farmProductsNew.css";
import "./farmProductPage.css";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const optionsPie = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
};

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#44a99a",
  },
  "& .MuiRating-iconHover": {
    color: "#44a99a",
  },
});

const textfieldStyle = {
  color: "white",
  width: "100%",
  borderColor: "rgba(255, 255, 255, 0.8)",
  justifySelf: "center",
  opacity: 0.8,
  "& label.Mui-focused": {
    color: "white",
    borderColor: "rgba(255, 255, 255, 0.8)",
  },
  "& label": {
    color: "white",
    borderColor: "rgba(255, 255, 255, 0.8)",
  },
  "& textarea": {
    color: "white",
    borderColor: "rgba(255, 255, 255, 0.8)",
  },
  "& input": {
    color: "white",
    borderColor: "rgba(255, 255, 255, 0.8)",
  },
  "& .MuiInput-underline:after": {
    borderColor: "rgba(255, 255, 255, 0.8)",
    color: "white",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(255, 255, 255, 0.8)",
      color: "white",
    },
    "&:hover fieldset": {
      borderColor: "rgba(255, 255, 255, 0.8)",
      color: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(255, 255, 255, 0.8)",
      color: "white",
    },
  },
};

const textfieldStyleGreen = {
  color: "#44a99a",
  width: "100%",
  borderColor: "#44a99a",
  justifySelf: "center",
  opacity: 0.8,
  "& label.Mui-focused": {
    color: "#44a99a",
    borderColor: "#44a99a",
  },
  "& label": {
    color: "#44a99a",
    borderColor: "#44a99a",
  },
  "& textarea": {
    color: "#44a99a",
    borderColor: "#44a99a",
  },
  "& input": {
    color: "#44a99a",
    borderColor: "#44a99a",
  },
  "& .MuiInput-underline:after": {
    borderColor: "#44a99a",
    color: "#44a99a",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#44a99a",
      color: "#44a99a",
    },
    "&:hover fieldset": {
      borderColor: "#44a99a",
      color: "#44a99a",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#44a99a",
      color: "#44a99a",
    },
  },
};

const textData = {
  store: ["Hållbarhet", "Shelf life"],
  color: ["Färg", "Color"],
  taste: ["Smak", "Taste"],
  days: ["dagar", "days"],
  kitchen: ["KÖKET", "KITCHEN"],
  health: ["HÄLSA", "HEALTH"],
  climate: ["KLIMAT", "EMISSIONS"],
  other: ["ÖVRIGT", "OTHER"],
  trace: ["SPÅRA", "TRACE"],
  climateFormLabel: ["Vikt", "Weight"],
  climateTotalPower: ["Total Elkonsumtion: ", "Total Power Consumption: "],
  climatePowerCo2: ["El Koldioxidförbrukning: ", "Power CO2 Emissions: "],
  climateTotalWater: ["Total Vattenkonsumtion: ", "Total Water Consumption: "],
  climateWaterCo2: ["Vatten Koldioxidförbrukning: ", "Water CO2 Emissions: "],
  climateTotalCo2: [
    "Total Koldioxidförbrukning: ",
    "Total Grow CO2 Emissions: ",
  ],
  climatePieWater: ["VATTEN", "WATER"],
  climatePiePower: ["EL", "POWER"],
  climatePieOther: ["ÖVRIGT", "OTHER"],
  climatePieText: ["Koldioxidavtryck: ", "CO2 Footprint: "],
  traceFormLabel: ["Datum", "Date"],
  packaging: ["Förpackning:", "Packaging:"],
  storing: ["Förvaring:", "Storing:"],
  leadtime: ["Ledtid:", "Lead time:"],
};

function FarmProductPage(props) {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const userLanguage = useSelector((state) => state.app.language);

  const [productId, setProductId] = useState(null);
  const [traceDate, setTraceDate] = useState("");
  const [traceDateHarvest, setTraceDateHarvest] = useState("");
  const [traceDateArr, setDateTraceArr] = useState(null);
  const [productSize, setProductSize] = useState("");

  const handleTraceChange = (event) => {
    setTraceDateHarvest(event.target.value);

    for (let i = 0; i < traceDateArr.length; i++) {
      if (
        traceDateArr[i].seed < event.target.value - 864000000 &&
        traceDateArr[i].seed > event.target.value - 1296000000
      ) {
        return setTraceDate(traceDateArr[i].seed);
      }
    }
  };
  const handleSizeChange = (event) => {
    setProductSize(event.target.value);
  };
  const calculatePower = () => {
    let sizeFactor = productId.tray / productSize;
    let consumptionWatt = 36 / sizeFactor;
    let totalConsumption = consumptionWatt * 16 * 5;
    return (totalConsumption / 1000).toFixed(2);
  };

  const calculateWater = () => {
    let sizeFactor = productId.tray / productSize;
    let consumptionWater = productId.water / sizeFactor;
    let totalConsumption = consumptionWater * 3;
    return totalConsumption.toFixed(2);
  };

  const calculateEmissions = () => {
    let tempPower = Number(calculatePower()) * 12;
    let tempWater = Number(calculateWater()) * 0.045;
    let tempDiff;
    if (tempPower > tempWater) {
      tempDiff = tempPower - tempWater;
    } else {
      tempDiff = tempWater - tempPower;
    }
    let tempRest = (tempDiff / 2) * productId.emissionFactor;

    return { power: tempPower, water: tempWater, rest: tempRest };
  };

  const pieData = (i) => {
    return {
      labels: [
        textData.climatePiePower[userLanguage],
        textData.climatePieWater[userLanguage],
        textData.climatePieOther[userLanguage],
      ],
      datasets: [
        {
          data: [
            Number(calculateEmissions().power.toFixed(2)),
            Number(calculateEmissions().water.toFixed(2)),
            Number(calculateEmissions().rest.toFixed(2)),
          ],
          backgroundColor: [
            "rgba(250, 250, 250, 0.8)",
            "rgba(250, 250, 250, 0.8)",
            "rgba(250, 250, 250, 0.8)",
          ],
          borderColor: [
            "rgba(0, 155, 140, 0.9)",
            "rgba(0, 155, 140, 0.9)",
            "rgba(0, 155, 140, 0.9)",
          ],
          borderWidth: 1,
          hoverOffset: 1,
        },
      ],
    };
  };

  useEffect(() => {
    scroll.scrollToTop();
  }, []);

  useEffect(() => {
    if (params.productId) {
      let result = plantInfoNew.find((item) => item.link === params.productId);

      if (result === undefined) {
        return navigate("/farm");
      }
      console.log(result);
      logEvent(analyticsObj, "page_view", { name: result.id });
      setProductId(result);
    } else {
      return navigate("/farm");
    }
  }, []);

  const getHarvestDates = async (tempPlant) => {
    const tempDates = await getDates(tempPlant);
    console.log(tempDates);
    setDateTraceArr(tempDates);
  };

  useEffect(() => {
    if (productId) {
      setProductSize(productId.size[0]);
    }
  }, [productId]);

  useEffect(() => {
    if (productId) {
      getHarvestDates(productId.id);
    }
  }, [productId]);

  useEffect(() => {
    const queryParam = new URLSearchParams(location.search).get('date');
    console.log(queryParam);
    if (queryParam && traceDateArr && traceDateArr.length > 0 && traceDateHarvest === "") {
     setTraceDateHarvest(Number(queryParam));
      for (let i = 0; i < traceDateArr.length; i++) {
        if (
          Number(traceDateArr[i].seed) < Number(queryParam) - 864000000 &&
          Number(traceDateArr[i].seed) > Number(queryParam) - 1296000000
        ) {
          console.log(traceDateArr[i].seed);
          setTraceDate(Number(traceDateArr[i].seed));
          return scroller.scrollTo('ProductTrace', {
            duration: 1500,
            delay: 100,
            smooth: true,
            offset: -30, // Scrolls to element + 50 pixels down the page
})
        }
      }
    }
  }, [location, traceDateArr]);

  return productId ? (
    <div className="FarmProductsPage">
      <div className="FarmProductsPageHeader">
        <div className="FarmProductsPageHeaderNav">
          <IconButton
            aria-label="back"
            size="large"
            color="inherit"
            onClick={() => navigate("/farm")}
          >
            <ArrowBackIosOutlinedIcon fontSize="inherit" color="inherit" />
          </IconButton>
        </div>
        <div className="FarmProductsPageHeaderDiv">
          <p className="FarmProductsPageHeaderTitle">
            {productId.name[userLanguage]}
          </p>
          <p className="FarmProductsPageHeaderText">{productId.latin}</p>
        </div>
        <div className="FarmProductsPageHeaderLogo">
          <img
            src={productId.icon}
            srcSet={productId.icon}
            alt={productId.name[userLanguage]}
            loading="lazy"
            className="FarmProductsPageLogo"
          />
        </div>
      </div>
      <div className="FarmProductsPageTop">
        <div className="FarmProductsPageSlider">
          <Carousel
            autoPlay
            infiniteLoop
            showStatus={false}
            showThumbs={false}
            interval={5000}
          >
            {productId.urlArr.map((item, index) => (
              <img src={item} alt={productId.name[userLanguage]} />
            ))}
          </Carousel>
          <div className="FarmProductsPageRating">
            <div className="FarmProductsPageRatingItem">
              <Typography component="legend">
                {textData.store[userLanguage]}
              </Typography>
              <StyledRating
                name="customized-color"
                defaultValue={productId.rateStore}
                precision={0.5}
                readOnly
                max={3}
                icon={<AccessTimeFilledIcon fontSize="inherit" />}
                emptyIcon={<AccessTimeIcon fontSize="inherit" />}
              />
              <Typography variant="caption">
                {productId.shelfLife} {textData.days[userLanguage]}
              </Typography>
            </div>
            <div className="FarmProductsPageRatingItem">
              <Typography component="legend">
                {textData.color[userLanguage]}
              </Typography>
              <StyledRating
                name="customized-color"
                defaultValue={productId.rateTaste}
                precision={0.5}
                readOnly
                max={3}
                icon={<ColorLensIcon fontSize="inherit" />}
                emptyIcon={<ColorLensOutlinedIcon fontSize="inherit" />}
              />
              <Typography variant="caption">
                {productId.color[userLanguage]}
              </Typography>
            </div>
            <div className="FarmProductsPageRatingItem">
              <Typography component="legend">
                {textData.taste[userLanguage]}
              </Typography>
              <StyledRating
                name="customized-color"
                defaultValue={productId.rateSpicy}
                precision={0.5}
                readOnly
                max={3}
                icon={<OfflineBoltIcon fontSize="inherit" />}
                emptyIcon={<OfflineBoltOutlinedIcon fontSize="inherit" />}
              />
              <Typography variant="caption">
                {productId.flavor[userLanguage]}
              </Typography>
            </div>
          </div>
        </div>
      </div>
      <div className="FarmProductsPageAbout">
        <div className="FarmProductsPageAboutSection">
          <div className="FarmProductsPageAboutTitle">INFO</div>
          <div className="FarmProductsPageAboutTags">
            {productId.description.map((descriptionItem, index) => (
              <div className="FarmProductsPageAboutText">
                {descriptionItem[userLanguage]}
              </div>
            ))}
          </div>
        </div>
        <div className="FarmProductsPageAboutSection">
          <div
            className="FarmProductsPageAboutTitle"
            style={{ color: "#44a99a", backgroundColor: "white" }}
          >
            {textData.kitchen[userLanguage]}
          </div>
          <div
            className="FarmProductsPageAboutTags"
            style={{ color: "#44a99a", backgroundColor: "#44a99a" }}
          >
            {productId.kitchen.map((kitchenItem, index) => (
              <div
                className="FarmProductsPageAboutTag"
                style={{ color: "#44a99a", backgroundColor: "white" }}
              >
                {kitchenItem[userLanguage]}
              </div>
            ))}
          </div>
        </div>
        <div className="FarmProductsPageAboutSection">
          <div className="FarmProductsPageAboutTitle">
            {textData.health[userLanguage]}
          </div>
          <div className="FarmProductsPageAboutTags">
            {productId.nutrientList.map((kitchenItem, index) => (
              <div className="FarmProductsPageAboutTag">
                {kitchenItem[userLanguage]}
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="FarmProductsPageInfo">
        <div className="TraceFactsOuterPower">
          <div className="FarmProductsPagePowerHeader">
            <p className="FarmProductsPagePowerHeaderTitle">
              {textData.climate[userLanguage]}
            </p>
            <FormControl
              size="small"
              fullWidth
              sx={{
                maxWidth: "120px",
                marginRight: "4%",
                marginTop: "40px",
                marginBottom: "18px",
                ...textfieldStyle,
              }}
            >
              <InputLabel id="demo-simple-select-label">
                {textData.climateFormLabel[userLanguage]}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={productSize}
                label={textData.climateFormLabel[userLanguage]}
                onChange={handleSizeChange}
              >
                {productId.size.map((sizeItem, sizeIndex) => (
                  <MenuItem key={sizeIndex} value={sizeItem}>
                    {sizeItem} g
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="TraceFactsOuterPowerInnerText">
            <p className="TraceFactsTinyTitle">
              {textData.climateTotalPower[userLanguage]}
              <span className="TraceFactsTinyText">{calculatePower()}kWh</span>
            </p>
            <p className="TraceFactsTinyTitle">
              {textData.climatePowerCo2[userLanguage]}
              <span className="TraceFactsTinyText">
                {(Number(calculatePower()) * 13).toFixed(2)}g
              </span>
            </p>
            <p className="TraceFactsTinyTitle">
              {textData.climateTotalWater[userLanguage]}
              <span className="TraceFactsTinyText">{calculateWater()}l</span>
            </p>
            <p className="TraceFactsTinyTitle">
              {textData.climateWaterCo2[userLanguage]}
              <span className="TraceFactsTinyText">
                {(Number(calculateWater()) * 0.045).toFixed(2)}g
              </span>
            </p>
            <p className="TraceFactsTinyTitle">
              {textData.climateTotalCo2[userLanguage]}
              <span className="TraceFactsTinyText">
                {(
                  calculateEmissions().power +
                  calculateEmissions().water +
                  calculateEmissions().rest
                ).toFixed(2)}
                g
              </span>
            </p>
          </div>
          <div className="TraceFactsOuterPowerInner">
            <Pie data={pieData()} />
            <p className="TraceFactsTinyTitle">
              {textData.climatePieText[userLanguage]}
              <span className="TraceFactsTinyText">
                {(
                  (calculateEmissions().power +
                    calculateEmissions().water +
                    calculateEmissions().rest) /
                  (productSize / 1000)
                ).toFixed(2)}
                g/kg
              </span>
            </p>
          </div>
        </div>
      </div>

      
      <Element name="ProductTrace" className="FarmProductsPageTrace">
        {traceDateArr ? (
          <div className="FarmProductsPageTrace">
            <div className="FarmProductsPageTraceHeader">
              <p className="FarmProductsPageTraceHeaderTitle">
                {textData.trace[userLanguage]}
              </p>
              <FormControl
                size="small"
                fullWidth
                sx={{
                  maxWidth: "150px",
                  marginRight: "4%",
                  marginTop: "40px",
                  marginBottom: "18px",
                  ...textfieldStyleGreen,
                }}
              >
                <InputLabel id="demo-simple-select-label">
                  {textData.traceFormLabel[userLanguage]}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={traceDateHarvest}
                  label={textData.traceFormLabel[userLanguage]}
                  onChange={handleTraceChange}
                >
                  {traceDateArr.map((traceItem, traceIndex) =>
                    traceItem.harvest.map((harvestItem, harvestIndex) => (
                      <MenuItem key={"date" + harvestItem} value={harvestItem}>
                        {moment(harvestItem).format("YYYY-MM-DD")}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </div>
            <FarmProductTrace
              traceDate={traceDate}
              traceDateHarvest={traceDateHarvest}
              plant={productId}
            />
          </div>
        ) : null}
      </Element>
      <div
        className="FarmProductsPageTrace"
        style={{ color: "white", backgroundColor: "#44a99a" }}
      >
        <div
          className="FarmProductsPageTraceHeader"
          style={{ color: "white", backgroundColor: "#44a99a" }}
        >
          <p
            className="FarmProductsPageTraceHeaderTitle"
            style={{ color: "white", backgroundColor: "#44a99a" }}
          >
            {textData.other[userLanguage]}
          </p>
        </div>
        <div className="FarmProductsPageFacts">
          <div className="FarmProductsPageFactsInner">
            <p className="FarmProductsPageFactsTitle">
              {textData.packaging[userLanguage]}
            </p>
            {productId.size.map((sizeItem, sizeIndex) => (
              <p key={sizeIndex} className="FarmProductsPageFactsText">
                <b>{sizeItem}g</b> - EAN: {productId.ean[sizeIndex]}
              </p>
            ))}
          </div>
          <div className="FarmProductsPageFactsInner">
            <p className="FarmProductsPageFactsTitle">
              {textData.storing[userLanguage]}
            </p>
            <p className="FarmProductsPageFactsText">
              {productId.storeData[userLanguage]} - max {productId.storeTemp}°C
            </p>
            <p className="FarmProductsPageFactsTitle">
              {textData.leadtime[userLanguage]}
            </p>
            <p className="FarmProductsPageFactsText">
              {productId.leadTime} {textData.days[userLanguage]}
            </p>
          </div>
        </div>
      </div>
      <ContactOuter />
    </div>
  ) : (
    <Loading />
  );
}

export default FarmProductPage;
