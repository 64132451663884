import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import detectBrowserLanguage from "detect-browser-language";
import CloseIcon from "@mui/icons-material/Close";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import "./App.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Loading from "./components/misc/Loading";
import MainStart from "./views/main/start/MainStart";
import LabOuter from "./views/labs/LabOuter";
import FarmProductPage from "./views/farm/products/FarmProductPage";
import FarmOuter from "./views/farm/FarmOuter";
import { setLanguage } from "./components/redux/appSlice";

const textData = {
  gdpr: [
    "Denna webbplats använder cookies för en förbättrad användarupplevelse",
    "This website is using cookies for improved user experience",
  ],
};

function App() {
  const [loading, setLoading] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [loadApp, setLoadApp] = useState(true);
  const [gdpr, setGdpr] = useState(false);

  const userLanguage = useSelector((state) => state.app.language);
  const dispatch = useDispatch();

  const checkGDPR = () => {
    if (localStorage.getItem("gdpr")) {
      setGdpr(true);
    }
  };

  const setGDPR = () => {
    localStorage.setItem("gdpr", JSON.stringify(true));
    setGdpr(true);
  };

  useEffect(() => {
    if (gdpr === false) {
      console.log("GDPR");
      checkGDPR();
    }
  }, []);

  useEffect(() => {
    console.log(detectBrowserLanguage());
    if (localStorage.getItem("languageIndex")) {
      return dispatch(
        setLanguage(JSON.parse(localStorage.getItem("languageIndex")))
      );
    }
    if (detectBrowserLanguage() === "sv-SE") {
      return dispatch(setLanguage(0));
    } else {
      return dispatch(setLanguage(1));
    }
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        {loadApp ? (
          <div className="AppStart">
            <Routes>
              <Route path="/" element={<MainStart />} />
              <Route path="farm" element={<FarmOuter />} />
              <Route path="farm/info" element={<FarmOuter />} />
              <Route path="farm/products" element={<FarmOuter />} />
              <Route
                path="farm/product/:productId"
                element={<FarmProductPage />}
              />
              <Route path="farm/partners" element={<FarmOuter />} />
              <Route path="farm/contact" element={<FarmOuter />} />
              <Route path="lab" element={<LabOuter />} />
            </Routes>
          </div>
        ) : (
          <div className="AppLoading">
            <Loading loaded={loaded} loading={loading} />
          </div>
        )}
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={!gdpr}
        onClose={setGDPR}
        message={textData.gdpr[userLanguage]}
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={setGDPR}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </BrowserRouter>
  );
}

export default App;
