import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { firebaseConfig } from "../../.config/config";
import { getDatabase } from "firebase/database";
import moment from "moment";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
export const database = getDatabase(app);
export const analyticsObj = getAnalytics(app);

export const getDates = async (plant) => {
  const dbRef = collection(db, "microgreens", plant, "harvestData");

  let startDate = Date.now() - 5184000000;
  let seedStartDate = Date.now() - 1036800000;

  let tempArr = [];
  const q = query(
    dbRef,
    where("seed", "<", seedStartDate),
    where("seed", ">", startDate)
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    tempArr.push(doc.data());
  });
  return tempArr;
};

export const getPlant = async (plant, seedDate, harvestDate) => {
  let tempPlant;
  let darkPh = [];
  let darkPhDates = [];
  let lightPh = [];
  let lightPhDates = [];
  let tempEc = [];
  let tempEcDates = [];
  let tempDarkEc = [];
  let tempDarkEcDates = [];
  let tempDark = [];
  let tempDarkDates = [];
  let tempLight = [];
  let tempLightDates = [];
  let tempDarkWater = [];
  let tempDarkWaterDates = [];
  let tempLightWater = [];
  let tempLightWaterDates = [];
  let humDark = [];
  let humDarkDates = [];
  let humLight = [];
  let humLightDates = [];
  let coolTemp;

  const dbRef = doc(db, "microgreens", plant, "harvestData", seedDate);
  const docSnap = await getDoc(dbRef);
  if (docSnap.exists()) {
    tempPlant = docSnap.data();

    // Light pH
    const phRef = collection(
      db,
      "sensors",
      tempPlant.ph.toString(),
      "sensorData"
    );
    const phQ = query(
      phRef,
      where("time", ">", Number(tempPlant.lightActive)),
      where("time", "<", Number(harvestDate))
    );
    const phSnapshot = await getDocs(phQ);
    phSnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      lightPhDates.push(moment(doc.data().time).format("MM-DD HH:mm"));
      lightPh.push(doc.data().value);
    });

    //darkPh
    const phDarkRef = collection(db, "sensors", "ph1", "sensorData");
    const phDarkQ = query(
      phDarkRef,
      where("time", ">", Number(tempPlant.seed)),
      where("time", "<", Number(tempPlant.lightActive))
    );
    const phDarkSnapshot = await getDocs(phDarkQ);
    phDarkSnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      darkPhDates.push(moment(doc.data().time).format("MM-DD HH:mm"));
      darkPh.push(doc.data().value);
    });

    // EC Light
    const ecRef = collection(
      db,
      "sensors",
      tempPlant.ec.toString(),
      "sensorData"
    );
    const ecQ = query(
      ecRef,
      where("time", ">", Number(tempPlant.lightActive)),
      where("time", "<", Number(harvestDate))
    );
    const ecSnapshot = await getDocs(ecQ);
    ecSnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      tempEcDates.push(moment(doc.data().time).format("MM-DD HH:mm"));
      tempEc.push(doc.data().value);
    });

    // EC Dark
    const ecDarkRef = collection(db, "sensors", "ec1", "sensorData");
    const ecDarkQ = query(
      ecDarkRef,
      where("time", ">", Number(tempPlant.seed)),
      where("time", "<", Number(tempPlant.lightActive))
    );
    const ecDarkSnapshot = await getDocs(ecDarkQ);
    ecDarkSnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      tempDarkEcDates.push(moment(doc.data().time).format("MM-DD HH:mm"));
      tempDarkEc.push(doc.data().value / 2);
    });

    // Temp Light
    const tempRef = collection(
      db,
      "sensors",
      tempPlant.temp.toString(),
      "sensorData"
    );
    const tempQ = query(
      tempRef,
      where("time", ">", Number(tempPlant.lightActive)),
      where("time", "<", Number(harvestDate))
    );
    const tempSnapshot = await getDocs(tempQ);
    tempSnapshot.forEach((doc) => {
      tempLightDates.push(moment(doc.data().time).format("MM-DD HH:mm"));
      tempLight.push(doc.data().value);
    });

    // Temp Dark
    const tempDarkRef = collection(db, "sensors", "temp1", "sensorData");
    const tempDarkQ = query(
      tempDarkRef,
      where("time", ">", Number(tempPlant.seed)),
      where("time", "<", Number(tempPlant.lightActive))
    );
    const tempDarkSnapshot = await getDocs(tempDarkQ);
    tempDarkSnapshot.forEach((doc) => {
      tempDarkDates.push(moment(doc.data().time).format("MM-DD HH:mm"));
      tempDark.push(doc.data().value * 0.85);
    });

    // Hum Light
    const humRef = collection(
      db,
      "sensors",
      tempPlant.hum.toString(),
      "sensorData"
    );
    const humQ = query(
      humRef,
      where("time", ">", Number(tempPlant.lightActive)),
      where("time", "<", Number(harvestDate))
    );
    const humSnapshot = await getDocs(humQ);
    humSnapshot.forEach((doc) => {
      humLightDates.push(moment(doc.data().time).format("MM-DD HH:mm"));
      humLight.push(doc.data().value);
    });

    // Hum Dark
    const humDarkRef = collection(db, "sensors", "hum3", "sensorData");
    const humDarkQ = query(
      humDarkRef,
      where("time", ">", Number(tempPlant.seed)),
      where("time", "<", Number(tempPlant.lightActive))
    );
    const humDarkSnapshot = await getDocs(humDarkQ);
    humDarkSnapshot.forEach((doc) => {
      humDarkDates.push(moment(doc.data().time).format("MM-DD HH:mm"));
      humDark.push(doc.data().value);
    });

    // Water Light
    const waterRef = collection(db, "sensors", "waterTemp1", "sensorData");
    const waterQ = query(
      waterRef,
      where("time", ">", Number(tempPlant.lightActive)),
      where("time", "<", Number(harvestDate))
    );
    const waterSnapshot = await getDocs(waterQ);
    waterSnapshot.forEach((doc) => {
      tempLightWater.push(moment(doc.data().time).format("MM-DD HH:mm"));
      tempLightWaterDates.push(doc.data().value);
    });

    // Water Dark
    const waterDarkRef = collection(db, "sensors", "waterTemp2", "sensorData");
    const waterDarkQ = query(
      waterDarkRef,
      where("time", ">", Number(tempPlant.seed)),
      where("time", "<", Number(tempPlant.lightActive))
    );
    const waterDarkSnapshot = await getDocs(waterDarkQ);
    waterDarkSnapshot.forEach((doc) => {
      tempDarkWater.push(moment(doc.data().time).format("MM-DD HH:mm"));
      tempDarkWaterDates.push(doc.data().value * 0.9);
    });

    // Temp Cooler
    let coolSum = 0;
    let coolCount = 0;
    const coolRef = collection(db, "sensors", "cool1", "sensorData");
    const coolQ = query(
      coolRef,
      where("time", ">", Number(tempPlant.lightActive)),
      where("time", "<", Number(tempPlant.delivery))
    );
    const coolSnapshot = await getDocs(coolQ);
    coolSnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      coolSum += doc.data().value;
      coolCount += 1;
    });

    coolTemp = coolSum / coolCount;

    return {
      data: tempPlant,
      darkPh: darkPh,
      darkPhDates: darkPhDates,
      ec: tempEc,
      ecDates: tempEcDates,
      darkEc: tempDarkEc,
      darkEcDates: tempDarkEcDates,
      lightPh: lightPh,
      lightPhDates: lightPhDates,
      tempDark: tempDark,
      tempLight: tempLight,
      tempDarkDates: tempDarkDates,
      tempLightDates: tempLightDates,
      humDarkDates: humDarkDates,
      humDark: humDark,
      humLightDates: humLightDates,
      humLight: humLight,
      tempDarkWater: tempDarkWaterDates,
      tempDarkWaterDates: tempDarkWater,
      tempLightWater: tempLightWaterDates,
      tempLightWaterDates: tempLightWater,
      coolTemp: coolTemp,
    };
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }
};
