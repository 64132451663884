import React, { useRef } from "react";
import { useSpring, animated as a, config } from "@react-spring/web";
import { Element } from "react-scroll";
import { useSelector } from "react-redux";
import MainLogo from "../../../components/design/MainLogo";
import "./labsStart.css";

let innerHeight = require("ios-inner-height");

const textData = {
  subtitle: [
    "Vi tar Inomhusodling till Nya Höjder!",
    "We take Indoor Farming to New Heights!",
  ],
};
function LabsStart(props) {
  const ref = useRef();
  const userLanguage = useSelector((state) => state.app.language);

  const heightTransition = useSpring({
    to: { height: 0 },
    from: { height: innerHeight() },
    delay: 800,
    config: config.slow,
  });

  const titleTransition = useSpring({
    to: { y: 0 },
    from: { y: 100 },
    delay: 900,
    config: config.slow,
  });

  return (
    <Element style={{ height: innerHeight() }} name="lab" className="FarmStart">
      <div className="FarmStartOuter">
        <MainLogo logoColor={false} textColor={true} noStartAni />
        <a.div ref={ref} className="StartNewInnerTextOuter">
          <div className="StartNewInnerTextDiv">
            <a.p className="StartNewInnerTitle" style={titleTransition}>
              VERT.lab
            </a.p>
          </div>
          <div className="StartNewInnerTextDiv">
            <a.p className="StartNewInnerText" style={titleTransition}>
              {textData.subtitle[userLanguage]}
            </a.p>
          </div>
        </a.div>
      </div>
      <a.div className="FarmStartOverlay" style={heightTransition}>
        <div
          className="FarmStartOverlayLogo"
          style={{ height: innerHeight(), backgroundColor: "#323741" }}
        >
          <MainLogo logoColor={true} textColor={true} noStartAni />
        </div>
      </a.div>
    </Element>
  );
}

export default LabsStart;