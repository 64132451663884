import React from "react";
import { useSpring, animated as a } from "@react-spring/web";
import "./design.css";

function MainLogo(props) {
  const styles = useSpring({
    loop: true,
    config: { duration: 20000 },
    from: { transform: "rotate(0deg)" },
    to: { transform: "rotate(360deg)" },
  });

  const stylesV = useSpring({
    loop: false,
    config: { duration: 300 },
    from: { transform: props.noStartAni ? "scale(0.98)" : "scale(0.5)" },
    to: { transform: "scale(0.98)" },
  });

  const toneOut = useSpring({
    config: { duration: 1000 },
    opacity: props.textColor ? 0 : 1,
  });

  const toneOutLogo = useSpring({
    config: { duration: 1000 },
    fill: props.logoColor ? "white" : "#45aa9b",
    stroke: props.logoColor ? "white" : "#45aa9b",
  });
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      data-name="Layer 1"
      viewBox="0 0 800 800"
      className="MainLogo"
    >
      <a.g style={{ transformOrigin: "center center", ...styles, ...toneOut }}>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-41.4 251.957 -133.926)"
        >
          V
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="matrix(.78 -.63 .63 .78 163.92 121.67)"
        >
          E
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-35.9 258.14 -216.751)"
        >
          R
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-33.9 259.925 -261.166)"
        >
          T
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="matrix(.86 -.52 .52 .86 204.38 91.84)"
        >
          .
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-28.4 270.28 -388.118)"
        >
          f
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-25.85 280.473 -471.119)"
        >
          a
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-23.2 289.53 -571.55)"
        >
          r
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="matrix(.94 -.35 .35 .94 263.98 61.23)"
        >
          m
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-17.9 315.534 -860.26)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-15.24 335.329 -1079.87)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-12.59 363.33 -1390.557)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-9.95 405.908 -1863.98)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-8.1 502.541 -2613.035)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-4.68 634.132 -4402.783)"
        >
          -
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-2.07 1176.8 -10445.939)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(.93 -1959.457 24340.57)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(3.1 -445 7622.97)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(5.72 -147.33 4305.168)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(8.35 -36.317 3067.387)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(10.99 21.825 2419.405)"
        >
          E
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(14 67.128 1983.692)"
        >
          s
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(16.3 81.475 1754.075)"
        >
          t
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(18.94 99.204 1556.295)"
        >
          .
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(21.6 112.477 1408.44)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(24.25 122.803 1293.182)"
        >
          2
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(26.89 131.091 1200.754)"
        >
          0
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(29.53 137.893 1124.651)"
        >
          2
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(31.8 139.21 1067.809)"
        >
          0
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(34.79 148.386 1006.845)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(37.8 156.475 954)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(40.03 156.121 919.687)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="matrix(.74 .68 -.68 .74 640.97 125.86)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(45.27 162.108 851.987)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(47.89 164.652 823.413)"
        >
          -
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(50.51 166.936 797.651)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(53 169.005 774.285)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(56 170.822 749.323)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(58.38 172.675 733.251)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="matrix(.48 .87 -.87 .48 715.35 216.08)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(63.66 175.896 698.097)"
        >
          H
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="matrix(.4 .92 -.92 .4 730.92 245.77)"
        >
          a
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(68.95 178.694 667.708)"
        >
          l
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(71.61 180.005 653.866)"
        >
          m
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(74.26 181.203 640.978)"
        >
          s
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(76.91 182.343 628.834)"
        >
          t
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(79.56 183.396 617.408)"
        >
          a
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(82.2 184.363 606.66)"
        >
          d
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(84.83 185.255 596.536)"
        >
          ,
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(87.7 186.632 585.013)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(91.1 190.503 570.757)"
        >
          S
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(92.62 187.343 569.629)"
        >
          w
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(95.23 188.03 561.348)"
        >
          e
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(97.85 188.722 553.338)"
        >
          d
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(100.49 189.457 545.538)"
        >
          e
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(103.14 190.177 537.986)"
        >
          n
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(105.79 190.896 530.694)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(108.45 191.619 523.602)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(111.1 192.27 516.802)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(113.75 192.925 510.204)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(116.1 191.918 504.998)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(119.04 194.108 497.64)"
        >
          -
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(121.67 194.631 491.669)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(124.3 195.143 485.849)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(126.93 195.646 480.17)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(129.55 196.092 474.66)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(132.17 196.537 469.269)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(134.78 196.943 464.01)"
        >
          L
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(137.4 197.356 458.831)"
        >
          o
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(140.4 198.375 452.712)"
        >
          c
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(142.64 198.181 448.726)"
        >
          a
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(145.26 198.591 443.793)"
        >
          l
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(148.2 199.305 438.104)"
        >
          l
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(150.53 199.482 434.044)"
        >
          y
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(153.17 199.943 429.246)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(155.5 200.465 425.027)"
        >
          G
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(158.46 200.883 419.78)"
        >
          r
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(161.11 201.353 415.097)"
        >
          o
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(163.7 201.75 410.5)"
        >
          w
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="matrix(-.97 .23 -.23 -.97 494.27 752.78)"
        >
          n
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(169.07 202.78 401.229)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(171.9 204.045 396.22)"
        >
          C
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(174.2 202.895 392.31)"
        >
          r
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(176.95 203.945 387.695)"
        >
          o
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(179.07 202.606 384.08)"
        >
          p
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-177.87 204.456 378.846)"
        >
          s
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-175.26 204.794 374.384)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-172.64 205.148 369.899)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-170 205.58 365.371)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-167.35 206.043 360.808)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-164.7 206.508 356.222)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-162.05 206.973 351.609)"
        >
          -
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-159.39 207.483 346.945)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-156.74 207.962 342.262)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-154.1 208.416 337.538)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-151.46 208.864 332.769)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-148.82 209.32 327.94)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-146.1 210.35 322.625)"
        >
          V
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-143.56 210.189 318.11)"
        >
          e
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="scale(-1) rotate(39 883.919 -593.098)"
        >
          r
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-138.6 209.943 308.456)"
        >
          t
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-135.7 211.433 302.806)"
        >
          i
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-133.09 211.837 297.532)"
        >
          c
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-130.47 212.283 292.142)"
        >
          a
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-127.85 212.726 286.637)"
        >
          l
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-125.23 213.191 281.012)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-122.6 213.687 275.238)"
        >
          F
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-119.96 214.235 269.31)"
        >
          a
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-117.4 214.711 262.875)"
        >
          r
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-114.68 215.39 256.982)"
        >
          m
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-112.04 215.993 250.544)"
        >
          i
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-109.38 216.672 243.879)"
        >
          n
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-106.73 217.348 237.007)"
        >
          g
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-104.08 218.058 229.903)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-101.43 218.78 222.53)"
        >
          S
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-98.6 220.085 214.553)"
        >
          o
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-96.16 220.221 206.917)"
        >
          l
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-93.54 220.924 198.614)"
        >
          u
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-91.1 220.847 189.974)"
        >
          t
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-88.36 222.222 180.927)"
        >
          i
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-86 222.052 171.019)"
        >
          o
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-83.13 223.807 161.475)"
        >
          n
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-80.49 224.761 150.971)"
        >
          s
        </text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-77.9 225.512 139.454)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="matrix(.26 -.97 .97 .26 45.01 314.78)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-72.55 228.058 115.704)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-69.89 229.342 102.412)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-67 231.523 88.195)"
        ></text>
        <text
          fill="#231f20"
          fontFamily="Menlo-Bold, Menlo"
          fontSize="28.22"
          fontWeight="700"
          transform="rotate(-64.59 232.106 73.003)"
        >
          -
        </text>
      </a.g>
      <a.path
        style={{ transformOrigin: "center center", ...stylesV, ...toneOutLogo }}
        strokeMiterlimit="10"
        strokeWidth="5"
        d="M544.28 159.55c-10.87.82-55.76 4.21-92.24 37-21.69 19.5-30.51 40.18-36.86 56.86C400.44 292.21 392.44 364 389 428.66l-.09-.13c-3.7-47.09-7.76-91.28-10-102.68-3.16-15.83-7.59-38.28-27.73-58.42-15.62-15.61-32.8-22.19-40.3-25a126.91 126.91 0 00-57.23-6.64c-13.49 1.55-20.7 2.57-22.28 6.68-3.9 10.16 33.08 23.31 66.26 58.46a208.8 208.8 0 0133.41 48.19c14.13 28.12 19.07 54.83 29 108.26 1.38 7.44 2.46 13.53 3 17.1 2.23 14.35 3.2 25.88 6 47.75 1 7.6 2.07 15.78 4.38 32.14C383.2 624.15 388.13 659 392.17 659c11.59-.06 11.57-235.21 75.83-308.36a239.51 239.51 0 0032.63-44.57c9.92-17.68 16.28-36 29-72.61 15.09-43.45 21.57-56.34 36.4-63.69 5.46-2.71 13.31-5.23 12.87-7.54-.72-3.69-22.04-3.63-34.62-2.68zm-213 133c-7.07-7.23-18.73-17.3-17.9-18.14 1-1 27.48 12.45 40 32.13 12.08 19 16.7 58.45 18.39 94.33l.06.45-.05-.07v-.38c-8.14-56.8-18.39-85.67-40.54-108.36zm107.87-1.45c-9.86 25.24-19.78 51.17-25.28 78.05h0c5.82-54.9 13.28-85.6 35-122.32 10.79-18.21 28.78-32.75 31.11-31.3 3.18 1.95-22.4 28.29-40.87 75.53z"
      ></a.path>
    </svg>
  );
}

export default MainLogo;
