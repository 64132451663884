import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { useSelector } from "react-redux";

import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import Avatar from "@mui/material/Avatar";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { ButtonBase } from "@mui/material";

import "./farmProducts.css";
import "./farmProductsNew.css";
import "./farmProductPage.css";

ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const dialogStyle = {};

const textData = {
  planted: ["Planterad", "Planted"],
  by: ["av ", "by "],
  dark: ["Mörker ", "Dark "],
  days: [" dagar", " days"],
  light: ["Ljus", "Light"],
  harvest: ["Skördad", "Harvest"],
  stored: [" - Förvarad i ", " - Stored in "],
  water: ["Vatten", "Water"],
  climate: ["Klimat", "Climate"],
  temp: ["Temperatur", "Temperature"],
  hum: ["Luftfuktighet", "Humidity"],
};

function FarmProductTraceTimeline(props) {
  const [darkDialog, setDarkDialog] = useState(false);
  const [lightDialog, setLightDialog] = useState(false);
  const userLanguage = useSelector((state) => state.app.language);

  console.log(props);

  return (
    <div className="FarmProductsPageTraceResult">
      <Timeline position="alternate">
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0" }}
            align="right"
            variant="caption"
          >
            {moment(props.plantData.data.seed).format("YYYY-MM-DD")}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector />
            <TimelineDot variant="outlined">
              <Avatar
                sx={{ width: 70, height: 70 }}
                alt={props.plantData.data.seedBy}
                src="https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Flogo.png?alt=media"
              />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2, paddingTop: "20px" }}>
            <Typography variant="subtitle2">
              {textData.planted[userLanguage]}
            </Typography>
            <Typography variant="caption">
              {textData.by[userLanguage]}
              {props.plantData.data.seedBy}
            </Typography>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0", paddingTop: "80px" }}
            variant="caption"
          >
            {Math.floor(
              (props.plantData.data.lightActive - props.plantData.data.seed) /
                86400000
            )}
            {textData.days[userLanguage]}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector sx={{ height: 70 }} />
            <ButtonBase onClick={() => setDarkDialog(true)}>
              <TimelineDot variant="outlined">
                <DarkModeIcon
                  sx={{ width: 70, height: 70, color: "#44a99a" }}
                />
              </TimelineDot>
            </ButtonBase>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2, paddingTop: "100px" }}>
            <Typography variant="subtitle2">
              {textData.dark[userLanguage]}
            </Typography>
            <Typography variant="caption">
              {(
                props.plantData.tempDark.reduce((a, b) => a + b) /
                props.plantData.tempDark.length
              ).toFixed(1)}
              °C - pH
              {(
                props.plantData.darkPh.reduce((a, b) => a + b) /
                props.plantData.darkPh.length
              ).toFixed(1)}{" "}
              -{" "}
              {(
                props.plantData.darkEc.reduce((a, b) => a + b) /
                props.plantData.darkEc.length
              ).toFixed(2)}
              EC
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0", paddingTop: "80px" }}
            variant="caption"
          >
            {Math.floor(
              (props.harvestDate - props.plantData.data.lightActive) / 86400000
            )}
            {textData.days[userLanguage]}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector sx={{ height: 70 }} />
            <ButtonBase onClick={() => setLightDialog(true)}>
              <TimelineDot variant="outlined">
                <LightModeIcon
                  sx={{ width: 70, height: 70, color: "#44a99a" }}
                />
              </TimelineDot>
            </ButtonBase>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2, paddingTop: "100px" }}>
            <Typography variant="subtitle2">
              {textData.light[userLanguage]}
            </Typography>
            <Typography variant="caption">
              {(
                props.plantData.tempLight.reduce((a, b) => a + b) /
                props.plantData.tempLight.length
              ).toFixed(1)}
              °C - pH
              {(
                props.plantData.lightPh.reduce((a, b) => a + b) /
                props.plantData.lightPh.length
              ).toFixed(1)}{" "}
              -{" "}
              {(
                props.plantData.ec.reduce((a, b) => a + b) /
                props.plantData.ec.length
              ).toFixed(2)}
              EC
            </Typography>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent
            sx={{ m: "auto 0", paddingTop: "80px" }}
            align="left"
            variant="caption"
          >
            {moment(Number(props.harvestDate)).format("YYYY-MM-DD")}
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineConnector sx={{ height: 70 }} />
            <TimelineDot variant="outlined">
              <Avatar
                sx={{ width: 70, height: 70 }}
                alt={props.plantData.data.seedBy}
                src="https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Flogo.png?alt=media"
              />
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2, paddingTop: "100px" }}>
            <Typography variant="subtitle2">
              {textData.harvest[userLanguage]}
            </Typography>
            <Typography variant="caption">
              {textData.by[userLanguage]}
              {props.plantData.data.harvestBy[0]}
              {textData.stored[userLanguage]}
              {props.plantData.coolTemp.toFixed(1)}°C
            </Typography>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
      <Dialog
        maxWidth={"sm"}
        fullWidth
        onClose={() => setDarkDialog(false)}
        open={darkDialog}
        sx={dialogStyle}
      >
        <DialogTitle>{textData.dark[userLanguage]}</DialogTitle>
        <Line
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: "top",
              },
              title: {
                display: true,
                text: textData.climate[userLanguage],
              },
            },
            scales: {
              xAxis: {
                // The axis for this scale is determined from the first letter of the id as `'x'`
                // It is recommended to specify `position` and / or `axis` explicitly.
                display: false,
              },
            },
          }}
          data={{
            labels: props.plantData.tempDarkDates,
            datasets: [
              {
                label: textData.temp[userLanguage],
                data: props.plantData.tempDark,
                borderColor: "#44a99a",
                backgroundColor: "#44a99a",
              },
              {
                label: textData.hum[userLanguage],
                data: props.plantData.humDark,
                borderColor: "#4487a9",
                backgroundColor: "#4487a9",
              },
            ],
          }}
        />
        <Line
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: "top",
              },
              title: {
                display: true,
                text: textData.water[userLanguage],
              },
            },
            scales: {
              xAxis: {
                // The axis for this scale is determined from the first letter of the id as `'x'`
                // It is recommended to specify `position` and / or `axis` explicitly.
                display: false,
              },
            },
          }}
          data={{
            labels: props.plantData.darkPhDates,
            datasets: [
              {
                label: "pH",
                data: props.plantData.darkPh,
                borderColor: "#449ca9",
                backgroundColor: "#449ca9",
              },
              {
                label: "EC",
                data: props.plantData.darkEc,
                borderColor: "#44a987",
                backgroundColor: "#44a987",
              },
              {
                label: textData.temp[userLanguage],
                data: props.plantData.tempDarkWater,
                borderColor: "#44a99a",
                backgroundColor: "#44a99a",
              },
            ],
          }}
        />
      </Dialog>
      <Dialog
        maxWidth={"sm"}
        fullWidth
        onClose={() => setLightDialog(false)}
        open={lightDialog}
      >
        <DialogTitle>{textData.light[userLanguage]}</DialogTitle>
        <Line
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: "top",
              },
              title: {
                display: true,
                text: textData.climate[userLanguage],
              },
            },
            scales: {
              xAxis: {
                // The axis for this scale is determined from the first letter of the id as `'x'`
                // It is recommended to specify `position` and / or `axis` explicitly.
                display: false,
              },
            },
          }}
          data={{
            labels: props.plantData.lightPhDates,
            datasets: [
              {
                label: textData.temp[userLanguage],
                data: props.plantData.tempLight,
                borderColor: "#44a99a",
                backgroundColor: "#44a99a",
              },
              {
                label: textData.hum[userLanguage],
                data: props.plantData.humLight,
                borderColor: "#4487a9",
                backgroundColor: "#4487a9",
              },
            ],
          }}
        />
        <Line
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: "top",
              },
              title: {
                display: true,
                text: textData.water[userLanguage],
              },
            },
            scales: {
              xAxis: {
                // The axis for this scale is determined from the first letter of the id as `'x'`
                // It is recommended to specify `position` and / or `axis` explicitly.
                display: false,
              },
            },
          }}
          data={{
            labels: props.plantData.lightPhDates,
            datasets: [
              {
                label: "pH",
                data: props.plantData.lightPh,
                borderColor: "#449ca9",
                backgroundColor: "#449ca9",
              },
              {
                label: "EC",
                data: props.plantData.ec,
                borderColor: "#44a987",
                backgroundColor: "#44a987",
              },
              {
                label: textData.temp[userLanguage],
                data: props.plantData.tempLightWater,
                borderColor: "#44a99a",
                backgroundColor: "#44a99a",
              },
            ],
          }}
        />
      </Dialog>
    </div>
  );
}

export default FarmProductTraceTimeline;
