import React, { useState } from "react";
import { useSpring, animated as a } from "@react-spring/web";
import { Element } from "react-scroll";
import { Waypoint } from "react-waypoint";
import { useRef } from "react";
import { useSelector } from "react-redux";

import "./farmInfo.css";

let innerHeight = require("ios-inner-height");

const textData = {
  paragraph1: [
    "På VERT.farm är vi dedikerade till att revolutionera sättet du tänker på din mat. Vår toppmoderna vertikala inomhusodling erbjuder den perfekta miljön för att odla de färskaste och mest smakrika Microgreens.",
    "At VERT.farm, we're dedicated to revolutionizing the way you think about your food. Our state-of-the-art indoor vertical farm provides the perfect environment for growing the freshest, most flavorful microgreens available.",
  ],
  paragraph2: [
    "Microgreens är små skott av grönsaker som ger en kraftfull smak och näring. De är en utsökta till sallader, smörgåsar och smoothies, samt ett enkelt sätt att få i sig ett brett utbud av vitaminer, mineraler och antioxidanter.",
    "Microgreens are the tiny shoots of baby greens that pack a powerful punch of flavor and nutrition. They're a delicious addition to salads, sandwiches, and smoothies, and they're an easy way to get the benefits of a wide range of vitamins, minerals, and antioxidants.",
  ],
  paragraph3: [
    "På VERT.farm använder vi ett egenutvecklat med den senaste tekniken för att skapa en kontrollerad miljö som är perfekt för odling av mikrogrönt. Det vertikalt staplade systemet maximerar utrymmet, medan vårt belysnings- och hydroponiska system ser till att våra växter får precis vad de behöver för att trivas.",
    "At VERT.farm, we use a custom system developed in-house with cutting-edge technology to create a controlled environment that's perfect for growing microgreens. Our vertically stacked growing trays maximize space, while our advanced lighting and hydroponic systems ensure that our plants receive exactly what they need to thrive.",
  ],
  paragraph4: [
    "Vårt engagemang för hållbarhet är kärnan i allt vi gör. Vi använder endast giftfria material av högsta kvalitet och ca 90% av vårt vatten återvinns, så att du kan känna dig bra över att stödja en farm som är snäll mot planeten.",
    "Our commitment to sustainability is at the heart of everything we do. We use only the highest-quality, non-toxic materials and around 90% of our water is recycled, so you can feel good about supporting a farm that's kind to the planet.",
  ],
  paragraph5: [
    "När det kommer till smak tror vi att färskt är bäst. Det är därför vi skördar våra Microgreens samma dag som leverans, så att du kan njuta av smaken av nyplockade grödor när du vill.",
    "And when it comes to flavor, we believe that fresh is best. That's why we harvest our microgreens same day as delivery, so you can enjoy the taste of just-picked greens any time you want.",
  ],
  paragraph6: [
    "Så varför vänta? Testa färska och näringsrika microgreens från VERT.farm och upptäck skillnaden själv!",
    "So why wait? Try your fresh and nutritious microgreens from VERT.farm taste the difference for yourself?!",
  ],
  subtitle1: ["Färskt och näringsrikt", "Fresh and Nutritious"],
  subtitle2: [
    "Obesprutade grödor som skördas samma dag som leverans!",
    "Pesticide-free crops harvested the day of delivery!",
  ],
  sideTitle1: ["Färskt och näringsrikt", "Fresh and Nutritious"],
  sideText1: [
    "Microgreens odlade inomhus i en kontrollerad miljö, vilket säkerställer den högsta nivån av näring och smak",
    "Microgreens grown indoors are picked at their peak, ensuring the highest level of nutrition and flavor",
  ],
  sideTitle2: ["Tillgänglighet året runt", "Year-Round Availability"],
  sideText2: [
    "Odling inomhus möjliggör produktion året runt, oavsett väderförhållanden",
    "Vertical farms allow for year-round production, regardless of weather conditions",
  ],
  sideTitle3: ["Miljömässigt hållbart", "Environmentally Sustainable"],
  sideText3: [
    "Vertikala inomhusodlingar använder mindre vatten och resurser jämfört med traditionellt jordbruk, vilket minskar dess klimatavtryck",
    "Vertical farms use less water and resources compared to traditional agriculture, reducing their carbon footprint",
  ],
  sideTitle4: ["Fri från bekämpningsmedel", "Pesticide-Free"],
  sideText4: [
    "Genom odling inomhus i en kontrollerad miljö elimineras behovet av skadliga bekämpningsmedel.",
    "Indoor vertical farms use natural and organic methods, eliminating the need for harmful pesticides",
  ],
  sideTitle5: ["Stöd lokal livsmedelsproduktion", "Support Local Agriculture"],
  sideText5: [
    "Att äta lokalt producerade livsmedel stödjer lokalsamhällets ekonomi och minskar koldioxidavtrycket från transporter",
    "Buying from a local vertical farm supports your community's economy and reduces the carbon footprint of transportation",
  ],
};
function FarmInfo() {
  const refText = useRef();

  const [imageOne, setImageOne] = useState(false);
  const [imageTwo, setImageTwo] = useState(false);
  const [imageThree, setImageThree] = useState(false);
  const [imageFour, setImageFour] = useState(false);
  const [imageFive, setImageFive] = useState(false);

  const userLanguage = useSelector((state) => state.app.language);

  const factsDivOne = useSpring({
    opacity: imageOne ? 1 : 0,
  });

  const factsDivTwo = useSpring({
    opacity: imageTwo ? 1 : 0,
  });

  const factsDivThree = useSpring({
    opacity: imageThree ? 1 : 0,
  });

  const factsDivFour = useSpring({
    opacity: imageFour ? 1 : 0,
  });

  const factsDivFive = useSpring({
    opacity: imageFive ? 1 : 0,
  });

  return (
    <Element name="farm/info" className="FarmInfo">
      <div className="FarmInfoOuter">
        <div className="FarmInfoInnerDiv">
          <div className="FarmInfoMain" ref={refText}>
            <div className="FarmInfoHeaderTextDiv">
              <p>{textData.paragraph1[userLanguage]}</p>
              <p>{textData.paragraph2[userLanguage]}</p>
              <div className="FarmInfoMainImages">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fmizuna.jpg?alt=media"
                  alt="Shelf"
                  className="FarmInfosMainImgSmall"
                />
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fshelf.jpeg?alt=media"
                  alt="Shelf"
                  className="FarmInfosMainImg2"
                />
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fkale.jpg?alt=media"
                  alt="Shelf"
                  className="FarmInfosMainImgSmall"
                />
              </div>
              <p className="FarmInfoMainCursive">
                {textData.subtitle1[userLanguage]}
              </p>
              <p>{textData.paragraph3[userLanguage]}</p>
              <p>{textData.paragraph4[userLanguage]}</p>
              <div className="FarmInfoMainDivider"></div>
              <div className="FarmInfoMainImages">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fpakchoi4.jpg?alt=media"
                  alt="Shelf"
                  className="FarmInfosMainImg"
                />
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Famaranth4.jpg?alt=media"
                  alt="Shelf"
                  className="FarmInfosMainImg"
                />
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fasianmix4.jpeg?alt=media"
                  alt="Shelf"
                  className="FarmInfosMainImg"
                />
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fproducts%2Fredcabbage4.jpg?alt=media"
                  alt="Shelf"
                  className="FarmInfosMainImg"
                />
              </div>
              <div className="FarmInfoMainDividerBottom"></div>
              <p className="FarmInfoMainCursive">
                {textData.subtitle2[userLanguage]}
              </p>
              <p>{textData.paragraph5[userLanguage]}</p>
              <p>{textData.paragraph6[userLanguage]}</p>
            </div>
          </div>
          <div className="FarmInfoside">
            <div className="FarmInfoHeader">
              <p className="FarmInfoHeaderText">INFO</p>
            </div>
            <Waypoint
              onEnter={() => setImageOne(true)}
              onLeave={() => setImageOne(false)}
              topOffset={innerHeight() * 0.35}
              bottomOffset={innerHeight() * 0.35}
            >
              <div className="FarmInfosideDivOuter">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fbullsblood.jpg?alt=media"
                  alt="Shelf"
                  className="FarmInfosideBack"
                />
                <a.div style={factsDivOne} className="FarmInfosideDiv">
                  <p className="FarmInfosideDivTitle">
                    {textData.sideTitle1[userLanguage]}
                  </p>
                  <p className="FarmInfosideDivText">
                    {textData.sideText1[userLanguage]}
                  </p>
                </a.div>
              </div>
            </Waypoint>
            <Waypoint
              onEnter={() => setImageTwo(true)}
              onLeave={() => setImageTwo(false)}
              topOffset={innerHeight() * 0.35}
              bottomOffset={innerHeight() * 0.35}
            >
              <div className="FarmInfosideDivOuter">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fcress2.jpg?alt=media"
                  alt="Shelf"
                  className="FarmInfosideBack"
                />
                <a.div style={factsDivTwo} className="FarmInfosideDiv">
                  <p className="FarmInfosideDivTitle">
                    {textData.sideTitle2[userLanguage]}
                  </p>
                  <p className="FarmInfosideDivText">
                    {textData.sideText2[userLanguage]}
                  </p>
                </a.div>
              </div>
            </Waypoint>
            <Waypoint
              onEnter={() => setImageThree(true)}
              onLeave={() => setImageThree(false)}
              topOffset={innerHeight() * 0.35}
              bottomOffset={innerHeight() * 0.35}
            >
              <div className="FarmInfosideDivOuter">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fsango.jpg?alt=media"
                  alt="Shelf"
                  className="FarmInfosideBack"
                />
                <a.div style={factsDivThree} className="FarmInfosideDiv">
                  <p className="FarmInfosideDivTitle">
                    {textData.sideTitle3[userLanguage]}
                  </p>
                  <p className="FarmInfosideDivText">
                    {textData.sideText3[userLanguage]}
                  </p>
                </a.div>
              </div>
            </Waypoint>
            <Waypoint
              onEnter={() => setImageFour(true)}
              onLeave={() => setImageFour(false)}
              topOffset={innerHeight() * 0.35}
              bottomOffset={innerHeight() * 0.35}
            >
              <div className="FarmInfosideDivOuter">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fredkale.jpg?alt=media"
                  alt="Shelf"
                  className="FarmInfosideBack"
                />
                <a.div style={factsDivFour} className="FarmInfosideDiv">
                  <p className="FarmInfosideDivTitle">
                    {textData.sideTitle4[userLanguage]}
                  </p>
                  <p className="FarmInfosideDivText">
                    {textData.sideText4[userLanguage]}
                  </p>
                </a.div>
              </div>
            </Waypoint>
            <Waypoint
              onEnter={() => setImageFive(true)}
              onLeave={() => setImageFive(false)}
              topOffset={innerHeight() * 0.35}
              bottomOffset={innerHeight() * 0.35}
            >
              <div className="FarmInfosideDivOuter">
                <img
                  src="https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Ffenugreek.jpg?alt=media"
                  alt="Shelf"
                  className="FarmInfosideBack"
                />
                <a.div style={factsDivFive} className="FarmInfosideDiv">
                  <p className="FarmInfosideDivTitle">
                    {textData.sideTitle5[userLanguage]}
                  </p>
                  <p className="FarmInfosideDivText">
                    {textData.sideText5[userLanguage]}
                  </p>
                </a.div>
              </div>
            </Waypoint>
          </div>
        </div>
      </div>
    </Element>
  );
}

export default FarmInfo;
