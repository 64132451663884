import React from "react";
import { useSpring, animated as a } from "@react-spring/web";

import "./misc.css";
function Loading(props) {
  const fillAnimation = useSpring({
    from: {
      fill: "rgba(69, 170, 155, 0.2)",
    },
    to: [
      {
        fill: "rgba(69, 170, 155, 1)",
      },
      {
        fill: "rgba(69, 170, 155, 0.2)",
      },
    ],
    config: { duration: 1500 },
    loop: true,
  });
  const { x } = useSpring({
    from: { x: 0 },
    to: [{ x: 1 }, { x: 0 }],
    config: { duration: 1500 },
    loop: true,
  });

  return (
    <a.svg
      xmlns="http://www.w3.org/2000/svg"
      width="700.63"
      height="1005"
      data-name="Layer 1"
      viewBox="0 0 700.63 1005"
      className="Loading"
    >
      <a.path
        fill={fillAnimation.fill}
        stroke="#45aa9b"
        strokeMiterlimit="10"
        strokeWidth="5"
        strokeDasharray={3390}
        strokeDashoffset={x.to((x) => (1 - x) * 3390)}
        d="M628.88 3.59c-21.75 1.65-111.54 8.43-184.5 74-43.38 39-61 80.36-73.71 113.74-29.49 77.55-45.49 221.06-52.29 350.46l-.17-.27c-7.41-94.18-15.52-182.56-20.06-205.35-6.32-31.66-15.18-76.58-55.46-116.85-31.23-31.23-65.61-44.37-80.61-49.93-11-4.08-56.36-20-114.46-13.29-27 3.11-41.4 5.14-44.56 13.36-7.8 20.31 66.17 46.62 132.53 116.92 7.17 7.61 40 43 66.83 96.38 28.24 56.28 38.13 109.74 57.89 216.56 2.76 14.89 4.92 27 6 34.2 4.45 28.71 6.39 51.75 11.92 95.51 1.91 15.19 4.14 31.55 8.76 64.27 19.72 139.5 29.57 209.2 37.66 209.2 23.18-.12 23.14-470.42 151.66-616.72 9.35-10.64 38.15-40.91 65.25-89.15 19.86-35.34 32.57-72 58-145.2 30.17-86.93 43.12-112.7 72.75-127.39 10.93-5.42 26.64-10.47 25.75-15.08-1.39-7.38-44.03-7.27-69.18-5.37zM202.78 269.5c-14.13-14.47-37.44-34.61-35.79-36.28 2-2 55 24.89 80 64.26 24.17 38 33.4 116.89 36.77 188.65.05.3.09.62.13.92l-.09-.15v-.77c-16.21-113.48-36.72-171.24-81.02-216.63zm215.76-2.9c-19.74 50.49-39.57 102.33-50.58 156.1h0c11.62-109.82 26.54-171.22 70-244.65 21.59-36.43 57.57-65.49 62.23-62.6 6.43 4.05-44.72 56.69-81.65 151.17z"
      ></a.path>
    </a.svg>
  );
}

export default Loading;
