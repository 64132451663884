import React, { useState, useRef } from "react";
import { Element, animateScroll as scroller } from "react-scroll";
import { useSelector } from "react-redux";
import { plantInfo, plantInfoNew } from "../../../components/misc/enums";
import FarmProductItem from "./FarmProductItem";

import "./farmProducts.css";
import "./farmProductsNew.css";

let innerHeight = require("ios-inner-height");

const plantArray = [
  ["Bockhornsklöver", "Fenugreek"],
  ["Bull’s Blood", "Bull’s Blood"],
  ["Mangold", "Swiss Chard"],
  ["Dill", "Dill"],
  ["Persilja", "Parsley"],
  ["Basilika", "Basil"],
  ["Vitlök", "Garlic"],
    ["Majrova", "Marjoram"],
  ["Komatsuna", "Komatsuna"],
  ["Tatsoi", "Tatsoi"],
  ["Alfalfa", "Alfalfa"],
  ["Red Salat Bowl Baby Leafs", "Red Salat Bowl Baby Leafs"],
  ["Ice Queen Baby Leafs", "Ice Queen Baby Leafs"],
];

const textData = {
  title: [
    "Om ni söker ett näringsrikt och smakrikt tillskott till era måltider kan microgreens vara det ni behöver.",
    "If you're looking for a nutritious and flavorful addition to your meals, microgreens might be just what you need.",
  ],
  subtitle: [
    "Dessa små växter är fulla av vitaminer, mineraler och antioxidanter, och är otroligt mångsidiga i köket. Oavsett om du är en hemmakock eller professionell kock, kan microgreens tillföra färg och smak till sallader, smörgåsar, soppor och mycket mer. På den här sidan kommer vi att introducera dig för vårt urval av microgreens och förklara varför de är en utsökt ingrediens för alla matälskare.",
    "These tiny plants are packed with vitamins, minerals, and antioxidants, and they're incredibly versatile in the kitchen. Whether you're a home cook or a professional chef, microgreens can add a pop of color and flavor to salads, sandwiches, soups, and more.In this page, we'll introduce you to our selection of microgreens and explain why they're a must-have ingredient for any food lover.",
  ],
  subtitle2: [
    "Kontakta oss gärna om ni saknar något i vårt sortiment, vi tar gladeligen emot specialbeställningar! Utöver vårt ordinarie sortiment kan vi bland annat erbjuda följande sorter:",
    "Feel free to contact us if you are missing something from our range, we happily accept special orders! In addition to our regular assortment, we can offer the following varieties, among other things:",
    ],
    header: [
        'SORTIMENT',
        'PRODUCTS'
    ],
};

function FarmProductsNew(props) {

  const userLanguage = useSelector((state) => state.app.language);

  const ref = useRef();
  
  return (
    <Element name="farm/products" className="FarmProducts">
      <div className="FarmProductsInner" ref={ref}>
        <div className="FarmProductsHeader">
                  <p className="FarmProductsHeaderText">{textData.header[userLanguage]}</p>
        </div>
      </div>
      <div
        className="FarmProductsListOuter"
        style={{ marginTop: -innerHeight() * 1.01 }}
      >
        <div className="FarmProductsListTop">
          <p className="FarmProductsListTopTitle">
            {textData.title[userLanguage]}
          </p>
          <p className="FarmProductsListTopText">
            {textData.subtitle[userLanguage]}
          </p>
        </div>
        {plantInfoNew.map((item, index) => (
          <FarmProductItem
            key={index}
            item={item}
            className="FarmProductsListItem"
          />
        ))}
        <div className="FarmProductsListBottom">
          <p className="FarmProductsListBottomText">
                      {textData.subtitle2[userLanguage]}
          </p>
          <div className="FarmProductsListBottomTags">
            {plantArray.map((plantName, i) => (
              <div key={i} className="FarmProductsListBottomTag">
                    {plantName[userLanguage]}
              </div>
            ))}
          </div>
        </div>
      </div>
    </Element>
  );
}

export default FarmProductsNew;
