import { createSlice } from '@reduxjs/toolkit'

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    pathListener: true,
    language: 1,
  },
  reducers: {
    setPathListener: (state, action) => {
      state.pathListener = action.payload
    },
    setLanguage: (state, action) => {
      state.language = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setPathListener, setLanguage } = appSlice.actions

export default appSlice.reducer