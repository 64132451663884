import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import Loading from "../../../components/misc/Loading";
import { getPlant } from "../../../components/firebase/firestore";
import FarmProductTraceTimeline from "./FarmProductTraceTimeline";

import "./farmProducts.css";
import "./farmProductsNew.css";
import "./farmProductPage.css";

const accordionOuterStyle = {
  backgroundColor: "#44a99a",
  borderRadius: "0px",
  boxShadow: "none",
  width: "100%",
};
const accordionSummaryStyle = {
  backgroundColor: "white",
  borderRadius: "0px",
  boxShadow: "none",
  width: "100%",
  height: "1px",
};
const accordionDetailsStyle = {
  backgroundColor: "white",
  borderRadius: "0px",
  boxShadow: "none",
  minHeight: "70vh",
  width: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: 0,
};

let tempLoading = false;
function FarmProductTrace(props) {
  const [loading, setLoading] = useState(true);
  const [plantData, setPlantData] = useState(null);

  const getPlantData = async () => {
    setLoading(true);
    let tempPlant = await getPlant(
      props.plant.id,
      props.traceDate.toString(),
      props.traceDateHarvest
    );
    setPlantData(tempPlant);
    console.log(tempPlant);
    setLoading(false);
    tempLoading = false;
  };

  useEffect(() => {
    if (props.traceDate !== "" && tempLoading === false) {
      tempLoading = false;
      console.log(props.traceDate);
      console.log(props.traceDateHarvest);
      getPlantData();
    }
  }, [props.traceDate]);
  return (
    <div className="FarmProductsPageTraceInner">
      <Accordion expanded={props.traceDate !== ""} sx={accordionOuterStyle}>
        <AccordionSummary sx={accordionSummaryStyle}></AccordionSummary>
        <AccordionDetails sx={accordionDetailsStyle}>
          {loading ? (
            <Loading loading={0.5} />
          ) : (
            <FarmProductTraceTimeline
              plantData={plantData}
              harvestDate={props.traceDateHarvest}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default FarmProductTrace;
