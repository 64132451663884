import React, { useRef } from "react";
import IconButton from "@mui/material/IconButton";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import MailIcon from "@mui/icons-material/Mail";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useSelector } from "react-redux";

import Status from "./Status";
import "./contact.css";

let innerHeight = require("ios-inner-height");

const textData = {
  header: ["KONTAKTA OSS", "CONTACT US"],
};

function ContactOuter() {
  const userLanguage = useSelector((state) => state.app.language);

  const ref = useRef();
  return (
    <div className="ContactOuter">
      <div className="ContactOverlay" ref={ref}>
        <div className="ContactHeader">
          <p className="ContactHeaderText">{textData.header[userLanguage]}</p>
        </div>
      </div>
      <div className="ContactInner" style={{ marginTop: -innerHeight() }}>
        <div className="ContactInnerTop">
          <Status/>
        </div>
        <div className="ContactInnerLinksDiv">
          <a className="ContactInnerLink" href={"callto:+46723310041"}>
            <IconButton aria-label="phone">
              <SmartphoneIcon
                sx={{
                  fontSize: 80,
                  color: "white",
                }}
              />
            </IconButton>
          </a>
          <a className="ContactInnerLink" href={"mailto: admin@vert.farm"}>
            <IconButton aria-label="mail">
              <MailIcon
                sx={{
                  fontSize: 90,
                  color: "white",
                }}
              />
            </IconButton>
          </a>
          <a
            className="ContactInnerLink"
            target="_blank"
            rel="noreferrer"
            href={"https://www.facebook.com/vert.farm/"}
          >
            <IconButton aria-label="facebook">
              <FacebookIcon
                sx={{
                  fontSize: 80,
                  color: "white",
                }}
              />
            </IconButton>
          </a>
          <a
            className="ContactInnerLink"
            target="_blank"
            rel="noreferrer"
            href={"https://www.instagram.com/vert.farm/"}
          >
            <IconButton aria-label="instagram">
              <InstagramIcon
                sx={{
                  fontSize: 80,
                  color: "white",
                }}
              />
            </IconButton>
          </a>
        </div>
        <div className="ContactInnerDivider">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fbullsblood.jpg?alt=media"
            alt="Shelf"
            className="ContactInnerDividerImg"
          />
          <img
            src="https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fcress2.jpg?alt=media"
            alt="Shelf"
            className="ContactInnerDividerImg"
          />
          <img
            src="https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fredkale.jpg?alt=media"
            alt="Shelf"
            className="ContactInnerDividerImg"
          />
          <img
            src="https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fsango.jpg?alt=media"
            alt="Shelf"
            className="ContactInnerDividerImg"
          />
        </div>
        <div className="ContactInnerMain">
          <div className="ContactInnerMainDiv">
            <div className="ContactInnerMainAddress">
              <p className="ContactInnerMainAddressText">
                VERT.farm Sverige AB
              </p>
              <p className="ContactInnerMainAddressText">Björkallén 36</p>
              <p className="ContactInnerMainAddressText">313 33 Oskarström</p>
              <p className="ContactInnerMainAddressText">Sweden</p>
            </div>
            <div className="ContactInnerMainAddress">
              <p className="ContactInnerMainAddressNumber">
                Jimmy: +46723310041
              </p>
              <p className="ContactInnerMainAddressNumber">
                Ann-Helen: +46723310042
              </p>
            </div>
          </div>
          <div className="ContactInnerMapsDiv">
            <iframe
              title="Map"
              className={"MapsElement"}
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyCVCOdSlc7E6mjBRxzZACKF4UxGZz3Z8Ws&q=björkallén+36,oskarström"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactOuter;
