import React, { useState, useEffect } from "react";
import { useSpring, animated as a, config } from "@react-spring/web";
import { logEvent } from "firebase/analytics";
import "./start.css";

import { MainButtonFarm, MainButtonLabs } from "./MainButtons";
import { analyticsObj } from "../../../components/firebase/firestore";
import MainLogo from "../../../components/design/MainLogo";
import LanguagePicker from "../../../components/misc/LanguagePicker";

function MainStart() {
  const [grayscale, setGrayscale] = useState(false);
  const toneIn = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: config.molasses,
    delay: 300,
  });

  const toneOut = useSpring({
    config: { duration: 1000 },
    backgroundColor: grayscale ? "#45aa9b" : "white",
  });

  const toneOutButtons = useSpring({
    config: { duration: 50 },
    opacity: grayscale ? 0 : 1,
  });

  const topAnimation = useSpring({
    to: { top: 0 },
    from: { top: -window.innerHeight },
    config: { duration: 700 },
  });
  const bottomAnimation = useSpring({
    to: { bottom: 0 },
    from: { bottom: -window.innerHeight },
    config: { duration: 700 },
  });

  useEffect(() => {
    logEvent(analyticsObj, "page_view");
  }, []);

  return (
    <a.div style={toneOut} className="MainStart">
      <a.div
        style={{ ...toneIn, ...toneOutButtons }}
        className="MainStartInner"
      >
        <a.div style={topAnimation} className="MainStartInnerTop">
          <MainButtonFarm
            showButton={true}
            setGrayscale={setGrayscale}
            logoColor={grayscale}
            textColor={grayscale}
          />
        </a.div>
        <a.div style={bottomAnimation} className="MainStartInnerBottom">
          <MainButtonLabs
            setGrayscale={setGrayscale}
            logoColor={grayscale}
            textColor={grayscale}
          />
        </a.div>
      </a.div>
      <div className="MainStartLogoBack"></div>
      <a.div className="MainStartLogo">
        <MainLogo logoColor={grayscale} textColor={grayscale} />
      </a.div>
      <LanguagePicker />
    </a.div>
  );
}

export default MainStart;
//<ErtLogo/>
