import React, { useRef, useEffect, useState } from "react";
import { Waypoint } from "react-waypoint";

import "./contact.css";

let innerHeight = require("ios-inner-height");

function StatusItem(props) {
  const [shelfsActive, setShelfsActive] = useState(false);

  return (
    <Waypoint
      onEnter={() => setShelfsActive(true)}
      onLeave={() => setShelfsActive(false)}
      topOffset={innerHeight() * 0.25}
      bottomOffset={innerHeight() * 0.25}
    >
      <div className="StatusInnerImgOuter">
        <img
          className="StatusInnerImg"
          src="https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fstatus%2Flow.jpg?alt=media"
          alt="Shelf"
        />
              {shelfsActive ? (
                  < img
          className="StatusInnerImg"
              src="https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fstatus%2Fmedium.jpeg?alt=media"
              alt="Shelf"
              style={{ opacity: props.shelfOpacity }}
        />
              ) : null}
        

        {props.shelf[1].io.lights.level1.value === "true" && shelfsActive ? (
          <img
            className="StatusInnerImg"
            src="https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fstatus%2Flevel1.png?alt=media"
            alt="Shelf"
          />
        ) : null}
        {props.shelf[1].io.lights.level2.value === "true" && shelfsActive ? (
          <img
            className="StatusInnerImg"
            src="https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fstatus%2Flevel2.png?alt=media"
            alt="Shelf"
          />
        ) : null}
        {props.shelf[1].io.lights.level3.value === "true" && shelfsActive ? (
          <img
            className="StatusInnerImg"
            src="https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fstatus%2Flevel3.png?alt=media"
            alt="Shelf"
          />
        ) : null}
        {props.shelf[1].io.lights.level4.value === "true" && shelfsActive ? (
          <img
            className="StatusInnerImg"
            src="https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fstatus%2Flevel4.png?alt=media"
            alt="Shelf"
          />
        ) : null}
        {props.shelf[1].io.lights.level5.value === "true" && shelfsActive ? (
          <img
            className="StatusInnerImg"
            src="https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fstatus%2Flevel5.png?alt=media"
            alt="Shelf"
          />
        ) : null}
        {props.shelf[1].io.lights.level6.value === "true" && shelfsActive ? (
          <img
            className="StatusInnerImg"
            src="https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fstatus%2Flevel6.png?alt=media"
            alt="Shelf"
          />
        ) : null}
      </div>
    </Waypoint>
  );
}

export default StatusItem;
