import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonBase from "@mui/material/ButtonBase";
import { Waypoint } from "react-waypoint";
import { useSpring, animated } from "react-spring";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import ColorLensOutlinedIcon from "@mui/icons-material/ColorLensOutlined";

import "./farmProducts.css";
import "./farmProductsNew.css";

let innerHeight = require("ios-inner-height");

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#44a99a",
  },
  "& .MuiRating-iconHover": {
    color: "#44a99a",
  },
});

const textData = {
  store: ["Hållbarhet", "Shelf life"],
  color: ["Färg", "Color"],
  taste: ["Smak", "Taste"],
  days: ["dagar", "days"],
};

function FarmProductItem(props) {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const userLanguage = useSelector((state) => state.app.language);

  const animationVisible = useSpring({
    opacity: isVisible ? 1 : 0,
    y: isVisible ? 0 : 100,
  });

  return (
    <Waypoint
      onEnter={() => setIsVisible(true)}
      onLeave={() => setIsVisible(false)}
      topOffset={innerHeight() * -1}
      bottomOffset={innerHeight() * 0.1}
    >
      <ButtonBase
        sx={{ zIndex: 0 }}
        className="FarmProductsListItem"
        onClick={() => navigate("product/" + props.item.link)}
      >
        <animated.div
          className="FarmProductsListItemInner"
          style={animationVisible}
        >
          <img
            src={props.item.url}
            srcSet={props.item.url}
            alt={props.item.name[userLanguage]}
            loading="lazy"
            className="FarmProductsListImg"
          />
          <div className="FarmProductsListItemBottom">
            <div className="FarmProductsListItemBottomTop">
              <div className="FarmProductsListItemBottomTopFirst">
                <p className="FarmProductsListItemTitle">
                  {props.item.name[userLanguage]}
                </p>
                <p className="FarmProductsListItemLatin">{props.item.latin}</p>
              </div>
              <div className="FarmProductsListItemBottomTopLast">
                <img
                  src={props.item.icon}
                  srcSet={props.item.icon}
                  alt={props.item.name[userLanguage]}
                  loading="lazy"
                  className="FarmProductsListLogo"
                />
              </div>
            </div>
            <div className="FarmProductsListItemBottomBottom">
              <p className="FarmProductsListItemDescription">
                {props.item.subtitle[userLanguage]}
              </p>
            </div>
            <div className="FarmProductsListItemBottomRating">
              <div className="FarmProductsListItemBottomRatingItem">
                <Typography variant="caption">
                  {textData.store[userLanguage]}
                </Typography>
                <StyledRating
                  name="customized-color"
                  defaultValue={props.item.rateStore}
                  precision={0.5}
                  readOnly
                  max={3}
                  icon={<AccessTimeFilledIcon fontSize="inherit" />}
                  emptyIcon={<AccessTimeIcon fontSize="inherit" />}
                />
              </div>
              <div className="FarmProductsListItemBottomRatingItem">
                <Typography variant="caption">
                  {textData.color[userLanguage]}
                </Typography>
                <StyledRating
                  name="customized-color"
                  defaultValue={props.item.rateTaste}
                  precision={0.5}
                  readOnly
                  max={3}
                  icon={<ColorLensIcon fontSize="inherit" />}
                  emptyIcon={<ColorLensOutlinedIcon fontSize="inherit" />}
                />
              </div>
              <div className="FarmProductsListItemBottomRatingItem">
                <Typography variant="caption">
                  {textData.taste[userLanguage]}
                </Typography>
                <StyledRating
                  name="customized-color"
                  defaultValue={props.item.rateSpicy}
                  precision={0.5}
                  readOnly
                  max={3}
                  icon={<LocalDiningIcon fontSize="inherit" />}
                  emptyIcon={<LocalDiningIcon fontSize="inherit" />}
                />
              </div>
            </div>
          </div>
        </animated.div>
      </ButtonBase>
    </Waypoint>
  );
}

export default FarmProductItem;
