import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setPathListener } from "../../components/redux/appSlice";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import lodash from "lodash";
import { logEvent } from "firebase/analytics";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

import FarmStart from "./start/FarmStart";
import FarmInfo from "./info/FarmInfo";
import FarmProductsNew from "./products/FarmProductsNew";
import FarmPartners from "./partners/FarmPartners";
import ContactOuter from "../contact/ContactOuter";
import { analyticsObj } from "../../components/firebase/firestore";
import "./farmOuter.css";

const routesArray = [
  "farm/info",
  "farm/products",
  "farm/partners",
  "farm/contact",
];

function FarmOuter() {
  const [scrollValue, setScrollValue] = useState(0);

  useEffect(() => {
    let pathFromUrl = window.location.pathname.substring(1);
    console.log(pathFromUrl);
    if (routesArray.includes(pathFromUrl)) {
      scroller.scrollTo(pathFromUrl, {
        duration: 1000,
        delay: 50,
        smooth: true,
      });
    } else {
      scroll.scrollToTop();
    }
  }, []);

  const reportScroll = () => {
    let tempScroll = window.scrollY;
    setScrollValue(tempScroll);
  };

  useEffect(() => {
    logEvent(analyticsObj, 'page_view');
  }, []);
  
  useEffect(() => {
    window.addEventListener("scroll", lodash.throttle(reportScroll, 10), {
      passive: true,
    });
    return () =>
      window.removeEventListener("scroll", lodash.throttle(reportScroll, 10));
  }, []);

  return (
    <div className="FarmOuter">
      <FarmStart scrollValue={scrollValue} />
      <FarmInfo scrollValue={scrollValue} />
      <FarmProductsNew scrollValue={scrollValue} />
      <FarmPartners />
      <ContactOuter />
    </div>
  );
}

export default FarmOuter;
