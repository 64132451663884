import React from "react";
import { useEffect, useState } from "react";
import { logEvent } from "firebase/analytics";
import { animateScroll as scroll, scroller } from "react-scroll";

import LabsStart from "./start/LabsStart";
import LabIntro from "./intro/LabIntro";
import LabHow from "./how/LabHow";
import LabWho from "./who/LabWho";
import ContactOuter from "../contact/ContactOuter";
import LanguagePicker from "../../components/misc/LanguagePicker";
import { analyticsObj } from "../../components/firebase/firestore";

import "./labOuter.css";

const routesArray = ["lab/intro", "lab/how", "lab/who", "lab/why"];

function LabOuter() {
  const [scrollValue, setScrollValue] = useState(0);

  useEffect(() => {
    let pathFromUrl = window.location.pathname.substring(1);
    console.log(pathFromUrl);
    if (routesArray.includes(pathFromUrl)) {
      scroller.scrollTo(pathFromUrl, {
        duration: 1000,
        delay: 50,
        smooth: true,
      });
    } else {
      scroll.scrollToTop();
    }
  }, []);

  const reportScroll = () => {
    let tempScroll = window.scrollY;
    setScrollValue(tempScroll);
  };

  useEffect(() => {
    logEvent(analyticsObj, "page_view");
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", reportScroll, {
      passive: true,
    });
    return () => window.removeEventListener("scroll", reportScroll);
  }, []);

  return (
    <div className="LabOuter">
      <LabsStart scrollValue={scrollValue} />
      <LabIntro scrollValue={scrollValue} />
      <LabWho scrollValue={scrollValue} />
      <LabHow scrollValue={scrollValue} />
      <ContactOuter />
      <LanguagePicker />
    </div>
  );
}

export default LabOuter;
