import React from "react";
import IconButton from "@mui/material/IconButton";
import { useSelector, useDispatch } from "react-redux";

import { setLanguage } from "../redux/appSlice";
import "./misc.css";
function LanguagePicker() {
  const userLanguage = useSelector((state) => state.app.language);
  const dispatch = useDispatch();

  const setLanguageClick = (index) => {
    localStorage.setItem("languageIndex", JSON.stringify(index));
    dispatch(setLanguage(index));
  };
  return (
    <div className="LanguagePicker">
      <IconButton
        disabled={userLanguage === 0}
        size="small"
        onClick={() => setLanguageClick(0)}
      >
        <p
          className="LanguageText"
          style={{ color: userLanguage === 0 ? "#44a99a" : "#7a7a7a" }}
        >
          Sv
        </p>
      </IconButton>
      <IconButton
        disabled={userLanguage === 1}
        size="small"
        onClick={() => setLanguageClick(1)}
      >
        <p
          className="LanguageText"
          style={{ color: userLanguage === 1 ? "#44a99a" : "#7a7a7a" }}
        >
          En
        </p>
      </IconButton>
    </div>
  );
}

export default LanguagePicker;
