import React from "react";
import { Element } from "react-scroll";
import Plx from "react-plx";
import { useSelector } from "react-redux";

import "./labWho.css";

let innerHeight = require("ios-inner-height");

const textData = {
  title: ["Vem?", "Who?"],
  subtitle: [
    "Inomhusodling för alla",
    "Cloud-connected indoor farming for anyone",
  ],
  section1: [
    "Skalbar från mindre hemmaodlingar...",
    "Scalable from small time home use...",
  ],
  section2: [
    "...till industriell användning i stor skala",
    "...to full scale industrial use",
  ],
};

const DataOne = [
  {
    start: innerHeight() * 6.1,
    end: innerHeight() * 6.15,
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: "opacity",
      },
    ],
  },
];
const DataTwo = [
  {
    start: innerHeight() * 6.15,
    end: innerHeight() * 6.2,
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: "opacity",
      },
    ],
  },
];

function LabWho() {
  const userLanguage = useSelector((state) => state.app.language);

  return (
    <Element name="lab/who" className="LabWho">
      <div className="LabWho" style={{ height: innerHeight() * 1.4 }}>
        <div className="WhoInner">
          <div className="WhoHeader" style={{ height: innerHeight() * 0.4 }}>
            <p className="WhoTitle">{textData.title[userLanguage]}</p>
            <p className="WhoSubtitle">{textData.subtitle[userLanguage]}</p>
          </div>

          <img
            src={
              "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Fhome.jpg?alt=media"
            }
            alt="FirebaseLogo"
            className="IndustrialLogo"
            style={{ height: innerHeight() * 0.35 }}
          />

          <div className="WhoBottomDiv" style={{ height: innerHeight() * 0.3 }}>
            <Plx
              className="WhoPlxText"
              parallaxData={DataOne}
              animateWhenNotInViewport={true}
            >
              <p className="WhoText">{textData.section1[userLanguage]}</p>
            </Plx>
            <Plx
              className="WhoPlxTextHidden"
              parallaxData={DataTwo}
              animateWhenNotInViewport={true}
            >
              <p className="WhoText">{textData.section2[userLanguage]}</p>
            </Plx>
          </div>

          <img
            src={
              "https://firebasestorage.googleapis.com/v0/b/vert-farm-api.appspot.com/o/web%2Fimg%2Findustrial.jpg?alt=media"
            }
            alt="FirebaseLogo"
            className="IndustrialLogo"
            style={{ height: innerHeight() * 0.35 }}
          />
        </div>
      </div>
    </Element>
  );
}

export default LabWho;
