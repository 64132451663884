import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getDatabase, ref, onValue } from "firebase/database";
import LightModeIcon from "@mui/icons-material/LightMode";
import OpacityIcon from "@mui/icons-material/Opacity";
import ThermostatIcon from "@mui/icons-material/Thermostat";
import PercentIcon from "@mui/icons-material/Percent";
import ShowerIcon from "@mui/icons-material/Shower";
import InvertColorsIcon from "@mui/icons-material/InvertColors";
import Tooltip from "@mui/material/Tooltip";

import StatusItem from "./StatusItem";
import { database } from "../../components/firebase/firestore";
import { plantInfoNew } from "../../components/misc/enums";
import "./contact.css";

let innerHeight = require("ios-inner-height");

const textData = {
  lighting: ["BELYSNING", "LIGHTING"],
  watering: ["BEVATTNING", "WATER"],
  wateringOn: ["PÅ", "ON"],
  wateringOff: ["MINUTER SEDAN", "MINUTES AGO"],
  hum: ["LUFTFUKTIGHET", "HUMIDITY"],
  temp: ["TEMPERATUR", "TEMPERATURE"],
  plants: ["VÄXTER I SYSTEMET", "PLANTS IN THE SYSTEM"],
};

function Status() {
  const userLanguage = useSelector((state) => state.app.language);

  const [shelfs, setShelfs] = useState(null);
  const [opacityArr, setOpacityArr] = useState([]);

  useEffect(() => {
    const shelfRef = ref(database, "farmData/halmstad/shelfs");
    const unsubscribe = onValue(shelfRef, (snapshot) => {
      const data = Object.entries(snapshot.val());
      setShelfs(data);
    });

    //remember to unsubscribe from your realtime listener on unmount or you will create a memory leak
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (shelfs !== null && shelfs.length > 0) {
      let tempArr = [];
      for (let i = 0; i < shelfs.length; i++) {
        let tempOpacity = 0;
        const tempData = Object.entries(shelfs[i][1].io.lights);
        for (let j = 0; j < tempData.length; j++) {
          if (tempData[j][1].value === "true") {
            tempOpacity += 0.16;
          }
        }
        tempArr.push(tempOpacity);
      }
      setOpacityArr(tempArr);
    }
  }, [shelfs]);
  return shelfs !== null && shelfs.length > 0 ? (
    <div className="StatusOuter">
      <p className="StatusOuterTitle">STATUS</p>
      {shelfs.map((shelf, shelfIndex) => (
        <div key={shelfIndex} className="StatusItem">
          <p className="StatusInnerTitle">{shelf[1].name}</p>
          <StatusItem
            shelf={shelf}
            shelfOpacity={(opacityArr[shelfIndex] * 0.6).toFixed(2)}
          />
          <div className="StatusItemBottom">
            <div className="StatusItemBottomItem">
              <LightModeIcon color="inherit" sx={{ fontSize: "170%" }} />
              <p className="StatusItemBottomTitle">
                {textData.lighting[userLanguage]}
              </p>
              <p className="StatusItemBottomText">
                {(opacityArr[shelfIndex] * 100).toFixed()}%
              </p>
            </div>
            <div className="StatusItemBottomItem">
              <ThermostatIcon color="inherit" sx={{ fontSize: "170%" }} />
              <p className="StatusItemBottomTitle">
                {textData.temp[userLanguage]}
              </p>
              <p className="StatusItemBottomText">
                {shelf[1].sensors.temp.value.toFixed(1)}°C
              </p>
            </div>
            <div className="StatusItemBottomItem">
              <PercentIcon color="inherit" sx={{ fontSize: "170%" }} />
              <p className="StatusItemBottomTitle">
                {textData.hum[userLanguage]}
              </p>
              <p className="StatusItemBottomText">
                {shelf[1].sensors.hum.value.toFixed()}%
              </p>
            </div>
            <div className="StatusItemBottomItem">
              <ShowerIcon color="inherit" sx={{ fontSize: "170%" }} />
              <p className="StatusItemBottomTitle">
                {textData.watering[userLanguage]}
              </p>
              {shelf[1].io.pump.value === "true" ? (
                <p className="StatusItemBottomText">
                  {textData.wateringOn[userLanguage]}
                </p>
              ) : (
                <p className="StatusItemBottomTextSmall">
                  {Math.floor((Date.now() - shelf[1].io.pump.time) / 60000) +
                    " " +
                    textData.wateringOff[userLanguage]}
                </p>
              )}
            </div>
            <div className="StatusItemBottomItem">
              <InvertColorsIcon color="inherit" sx={{ fontSize: "170%" }} />
              <p className="StatusItemBottomTitle">pH</p>
              <p className="StatusItemBottomText">
                {shelf[1].sensors.ph.value.toFixed(2)}
              </p>
            </div>
            <div className="StatusItemBottomItem">
              <OpacityIcon color="inherit" sx={{ fontSize: "170%" }} />
              <p className="StatusItemBottomTitle">EC</p>
              <p className="StatusItemBottomText">
                {shelf[1].sensors.ec.value.toFixed(2)}
              </p>
            </div>
            <div className="StatusItemBottomItemWide">
              <p className="StatusItemBottomTitle">
                {textData.plants[userLanguage]}
              </p>
              <div className="StatusItemBottomItemWideBottom">
                {plantInfoNew.map((plantInfo, infoIndex) => {
                  if (plantInfo.shelf === shelf[0]) {
                    return (
                      <Tooltip
                        key={infoIndex + "plant"}
                        title={plantInfo.name[userLanguage]}
                      >
                        <img
                          className="StatusItemPlantLogo"
                          src={plantInfo.icon}
                          alt="Plant logo"
                        />
                      </Tooltip>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  ) : null;
}

export default Status;
